import { takeLatest, call, put, all } from 'redux-saga/effects';

import axios from 'axios';
import { updatePendencie } from './actions';

export function* refreshPendencies() {
  const { data } = yield call(axios.get, '/customer-register/pendencies');

  yield put(updatePendencie({ pendencies: data.pendencies }));
}
export default all([
  takeLatest('@company/UPDATE_PENDENCIE_REQUEST', refreshPendencies),
]);
