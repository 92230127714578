import React, { useState, memo } from 'react';

import {
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
  FormHelperText,
} from '@material-ui/core';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import StateSelect from '../../utils/StateSelect';
import {
  normalizeCep,
  normalizeCnpj,
  normalizeNumber,
  normalizePhone,
} from '../../utils/normalize';
import { validationPJ } from '../../utils/memberValidation';
import { signOut } from '../../../../../../store/modules/auth/actions';

function NewMemberPJ({ resetForm, loadingShow, loadingClose, addMember }) {
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    fullname: '',
    cpf: '',
    address: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    uf: '',
    zipcode: '',
    email: '',
    phone: '',
    type: null,
  });
  const [errors, setErrors] = useState({
    fullname: '',
    cpf: '',
    address: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    uf: '',
    zipcode: '',
    email: '',
    phone: '',
    type: '',
  });

  async function handleCep() {
    loadingShow();
    if (values.zipcode && values.zipcode.length === 9) {
      const api = axios.create({
        baseURL: 'https://viacep.com.br/ws',
      });
      delete api.defaults.headers.common.token;
      const response = await api.get(
        `/${values.zipcode.replace(/\D/g, '')}/json`
      );
      if (!response.data.erro) {
        const {
          uf,
          logradouro: address,
          bairro: neighborhood,
          localidade: city,
        } = response.data;
        setValues({
          ...values,
          uf,
          address,
          neighborhood,
          city,
        });
      }
    }
    loadingClose();
  }

  async function handleConsultCnpj() {
    loadingShow();
    try {
      const { data } = await axios.get(
        `/customer-register/member-consult-cnpj/${values.cpf.replace(
          /\D/g,
          ''
        )}`
      );
      setValues({ ...values, ...data, cpf: values.cpf });
    } catch (err) {
      console.log(err);
    }
    loadingClose();
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setErrors({
      fullname: '',
      cpf: '',
      address: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      uf: '',
      zipcode: '',
      email: '',
      phone: '',
      type: '',
    });
    const lock_error = await validationPJ(values);
    setErrors(lock_error);
    const keys = Object.keys(lock_error);
    for (let i = 0; i < keys.length; i += 1) {
      if (lock_error[keys[i]] !== '') {
        return;
      }
    }
    loadingShow();
    try {
      const response = await axios.post(`/customer-register/members`, values);
      toast.success('Membro adicionado com sucesso');
      resetForm();
      addMember(response.data);
    } catch (err) {
      if (
        (err.response && err.response.status === 401) ||
        (err.response && err.response.status === 403)
      ) {
        dispatch(signOut());
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde.'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente.');
      }
    }
    loadingClose();
  }

  return (
    <div>
      <h4 className="mb-4">Novo Sócio/Avalista</h4>
      <form onSubmit={handleSubmit}>
        <fieldset className="mb-4">
          <FormControl style={{ width: '100%' }}>
            <InputLabel id="type_label">Tipo de Sócio</InputLabel>
            <Select
              style={{ width: '100%' }}
              required
              value={values.type}
              onChange={(e) =>
                setValues({
                  ...values,
                  type: e.target.value,
                })
              }
              labelId="type_label"
              error={errors.type !== ''}
            >
              <MenuItem value={2}>Sócio</MenuItem>
              <MenuItem value={3}>Avalista</MenuItem>
            </Select>
            {errors.type !== '' && (
              <FormHelperText error>{errors.type}</FormHelperText>
            )}
          </FormControl>
        </fieldset>
        <fieldset>
          <legend className="mb-3">Dados Básicos</legend>
          <div className="wrap_fields">
            <TextField
              required
              label="CNPJ"
              value={values.cpf}
              onChange={(e) =>
                setValues({ ...values, cpf: normalizeCnpj(e.target.value) })
              }
              onBlur={handleConsultCnpj}
              error={errors.cpf !== ''}
              helperText={errors.cpf}
            />
            <TextField
              required
              label="Razão Social"
              value={values.fullname}
              onChange={(e) =>
                setValues({ ...values, fullname: e.target.value })
              }
              error={errors.fullname !== ''}
              helperText={errors.fullname}
            />
          </div>
        </fieldset>
        <fieldset>
          <legend className="mb-3">Endereço</legend>
          <div className="wrap_fields">
            <TextField
              required
              label="CEP"
              value={values.zipcode}
              onBlur={handleCep}
              onChange={(e) =>
                setValues({ ...values, zipcode: normalizeCep(e.target.value) })
              }
              error={errors.zipcode !== ''}
              helperText={errors.zipcode}
            />
            <TextField
              required
              label="Logradouro"
              value={values.address}
              onChange={(e) =>
                setValues({ ...values, address: e.target.value })
              }
              error={errors.address !== ''}
              helperText={errors.address}
            />
            <TextField
              required
              label="Número"
              value={values.number}
              onChange={(e) =>
                setValues({
                  ...values,
                  number: normalizeNumber(e.target.value),
                })
              }
              error={errors.number !== ''}
              helperText={errors.number}
            />
            <TextField
              label="Complemento"
              value={values.complement}
              onChange={(e) =>
                setValues({ ...values, complement: e.target.value })
              }
            />
            <TextField
              required
              label="Bairro"
              value={values.neighborhood}
              onChange={(e) =>
                setValues({ ...values, neighborhood: e.target.value })
              }
              error={errors.neighborhood !== ''}
              helperText={errors.neighborhood}
            />
            <TextField
              required
              label="Cidade"
              value={values.city}
              onChange={(e) => setValues({ ...values, city: e.target.value })}
              error={errors.city !== ''}
              helperText={errors.city}
            />
            <StateSelect
              required
              label="Estado"
              value={values.uf}
              onChange={(e) => setValues({ ...values, uf: e.target.value })}
              error={errors.uf !== ''}
              helperText={errors.uf}
            />
          </div>
        </fieldset>
        <fieldset>
          <legend className="mb-3">Dados de Contato</legend>
          <div className="wrap_fields">
            <TextField
              required
              type="email"
              label="Email"
              value={values.email}
              onChange={(e) => setValues({ ...values, email: e.target.value })}
              error={errors.email !== ''}
              helperText={errors.email}
            />
            <TextField
              required
              label="Telefone"
              value={values.phone}
              onChange={(e) =>
                setValues({ ...values, phone: normalizePhone(e.target.value) })
              }
              error={errors.phone !== ''}
              helperText={errors.phone}
            />
          </div>
        </fieldset>
        <div className="mt-3">
          <Button type="submit" color="primary" variant="contained">
            Salvar
          </Button>
          <Button
            type="button"
            color="primary"
            variant="outlined"
            className="ml-2"
            onClick={() => resetForm()}
          >
            Cancelar
          </Button>
        </div>
      </form>
    </div>
  );
}

export default memo(NewMemberPJ);
