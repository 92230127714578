import produce from 'immer';

const INITIAL_STATE = {
  company: null,
  pendencies: [],
  status: '',
  end: 0,
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/SIGN_IN_SUCCESS': {
        draft.company = action.payload.company;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.company = null;
        draft.pendencies = [];
        draft.status = '';
        draft.end = 0;
        break;
      }
      case '@company/SET_STATUS': {
        draft.pendencies = action.payload.pendencies;
        draft.status = action.payload.status;
        draft.end = action.payload.end;
        break;
      }
      case '@company/UPDATE_PENDENCIE': {
        draft.pendencies = action.payload.pendencies;
        break;
      }
      default:
    }
  });
}
