import React, { useState, useEffect, memo } from 'react';

import {
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
  FormHelperText,
} from '@material-ui/core';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Alert } from '@material-ui/lab';
import { useSelector, useDispatch } from 'react-redux';
import StateSelect from '../../utils/StateSelect';
import {
  normalizeDate,
  normalizeCpf,
  normalizeNumber,
  normalizeCep,
  normalizePhone,
} from '../../utils/normalize';

import { validationPF } from '../../utils/memberValidation';

import { updatePendencieRequest } from '../../../../../../store/modules/company/actions';
import { signOut } from '../../../../../../store/modules/auth/actions';

function EditMemberPF({
  resetForm,
  loadingShow,
  loadingClose,
  updateMember,
  member,
}) {
  const dispatch = useDispatch();
  const { pendencies, end, status } = useSelector((state) => state.company);
  const [pendencieIdentified, setPendencieIdentified] = useState(false);
  const [fieldsPendencies, setFieldsPendencies] = useState([]);
  const [fieldsPendenciesSpouses, setFieldsPendenciesSpouses] = useState([]);
  const [values, setValues] = useState({
    fullname: '',
    date_of_birth: '',
    cpf: '',
    e_cpf: '',
    identity_doc: '',
    issuing_agency: '',
    issuing_agency_uf: '',
    nationality: '',
    profession: '',
    marital_status: '',
    wedding_registry: '',
    address: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    uf: '',
    zipcode: '',
    email: '',
    phone: '',
    in_stable_union: '',
    in_politically_exposed_person: '',
    mother_name: '',
    type: null,
  });
  const [errors, setErrors] = useState({
    fullname: '',
    date_of_birth: '',
    cpf: '',
    e_cpf: '',
    identity_doc: '',
    issuing_agency: '',
    issuing_agency_uf: '',
    nationality: '',
    profession: '',
    marital_status: '',
    wedding_registry: '',
    address: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    uf: '',
    zipcode: '',
    email: '',
    phone: '',
    in_stable_union: '',
    in_politically_exposed_person: '',
    mother_name: '',
    type: '',
  });
  const [valuesSpouses, setValuesSpouses] = useState({
    fullname: '',
    date_of_birth: '',
    cpf: '',
    e_cpf: '',
    identity: '',
    identity_oe: '',
    identity_uf: '',
    profession: '',
    email: '',
    phone: '',
  });
  const [errorsSpouses, setErrorsSpouses] = useState({
    fullname: '',
    date_of_birth: '',
    cpf: '',
    e_cpf: '',
    identity: '',
    identity_oe: '',
    identity_uf: '',
    profession: '',
    email: '',
    phone: '',
  });

  useEffect(() => {
    if (Object.keys(member).length > 0) {
      setValues({ ...member, memberSpouses: undefined });
      setValuesSpouses({ ...member.memberSpouses });
    }
  }, [member]);

  function verifyDisabled(field) {
    if (end === 1) {
      if (status === 'A') {
        return true;
      }
      const pendencie = pendencies.find(
        (i) =>
          i.type === 'member' &&
          i.member_id === member.id &&
          i.data[field] !== undefined
      );
      if (pendencie) {
        if (!fieldsPendencies.find((i) => i === field)) {
          setFieldsPendencies([...fieldsPendencies, field]);
        }
        if (!pendencieIdentified) {
          setPendencieIdentified(true);
        }
        return false;
      }
      return true;
    }
    return false;
  }
  function verifyDisabledSpouses(field) {
    if (end === 1) {
      if (status === 'A') {
        return true;
      }
      const pendencie = pendencies.find(
        (i) =>
          i.type === 'member' &&
          i.member_id === member.id &&
          i.data.memberSpouses &&
          i.data.memberSpouses[field] !== undefined
      );
      if (pendencie) {
        if (!pendencieIdentified) {
          if (!fieldsPendenciesSpouses.find((i) => i === field)) {
            setFieldsPendenciesSpouses([...fieldsPendenciesSpouses, field]);
          }
          if (!pendencieIdentified) {
            setPendencieIdentified(true);
          }
        }
        return false;
      }
      if (
        fieldsPendencies.find(
          (i) => i === 'marital_status' || i === 'wedding_registry'
        )
      ) {
        if (!fieldsPendenciesSpouses.find((i) => i === field)) {
          setFieldsPendenciesSpouses([...fieldsPendenciesSpouses, field]);
        }
        return false;
      }
      return true;
    }
    return false;
  }

  async function handleCep() {
    loadingShow();
    if (values.zipcode && values.zipcode.length === 9) {
      const api = axios.create({
        baseURL: 'https://viacep.com.br/ws',
      });
      delete api.defaults.headers.common.token;
      const response = await api.get(
        `/${values.zipcode.replace(/\D/g, '')}/json`
      );
      if (!response.data.erro) {
        const {
          uf,
          logradouro: address,
          bairro: neighborhood,
          localidade: city,
        } = response.data;
        setValues({
          ...values,
          uf,
          address,
          neighborhood,
          city,
        });
      }
    }
    loadingClose();
  }

  async function handleResolvePendencie() {
    const lock_pendencies = pendencies.filter(
      (p) => p.type === 'member' && p.member_id === member.id
    );
    const body_data = [];
    lock_pendencies.forEach((i) => {
      const data_send = {
        memberSpouses: fieldsPendenciesSpouses.length === 0 ? null : {},
      };
      for (let c = 0; c < fieldsPendencies.length; c += 1) {
        data_send[fieldsPendencies[c]] = values[fieldsPendencies[c]];
      }
      for (let c = 0; c < fieldsPendenciesSpouses.length; c += 1) {
        data_send.memberSpouses[fieldsPendenciesSpouses[c]] =
          valuesSpouses[fieldsPendenciesSpouses[c]];
      }
      body_data.push({
        id: i.id,
        data: data_send,
      });
    });
    loadingShow();
    try {
      await axios.put('/customer-register/pendencies', {
        pendencies: body_data,
      });
      dispatch(updatePendencieRequest());
      toast.success('Pendência resolvida');
      updateMember();
      resetForm();
    } catch (err) {
      if (
        (err.response && err.response.status === 401) ||
        (err.response && err.response.status === 403)
      ) {
        dispatch(signOut());
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde.'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente.');
      }
    }
    return loadingClose();
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setErrors({
      fullname: '',
      date_of_birth: '',
      cpf: '',
      e_cpf: '',
      identity_doc: '',
      issuing_agency: '',
      issuing_agency_uf: '',
      nationality: '',
      profession: '',
      marital_status: '',
      wedding_registry: '',
      address: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      uf: '',
      zipcode: '',
      email: '',
      phone: '',
      in_stable_union: '',
      in_politically_exposed_person: '',
      mother_name: '',
      type: '',
    });
    setErrorsSpouses({
      fullname: '',
      date_of_birth: '',
      cpf: '',
      e_cpf: '',
      identity: '',
      identity_oe: '',
      identity_uf: '',
      profession: '',
      email: '',
      phone: '',
    });
    const { errors: errors_member, errors_spouses } = await validationPF(
      values,
      valuesSpouses
    );
    setErrors(errors_member);
    setErrorsSpouses(errors_spouses);
    const keys = Object.keys(errors_member);
    for (let i = 0; i < keys.length; i += 1) {
      if (errors_member[keys[i]] !== '') {
        return;
      }
    }
    const keys_spouses = Object.keys(errors_spouses);
    for (let i = 0; i < keys_spouses.length; i += 1) {
      if (errors_spouses[keys_spouses[i]] !== '') {
        return;
      }
    }
    if (pendencieIdentified) {
      handleResolvePendencie();
      return;
    }
    loadingShow();
    try {
      let obj;
      if (
        values.marital_status !== '' &&
        values.marital_status !== 'Solteiro(a)' &&
        values.marital_status !== 'Separado(a)/Divorciado(a)' &&
        values.marital_status !== 'Viúvo(a)' &&
        values.wedding_registry !== '' &&
        values.wedding_registry !== 'Separação Total de Bens'
      ) {
        obj = {
          ...values,
          memberSpouses: valuesSpouses,
        };
      } else {
        obj = {
          ...values,
        };
      }
      await axios.put(`/customer-register/members/${member.id}`, obj);
      toast.success('Membro salvo com sucesso');
      updateMember();
      resetForm();
    } catch (err) {
      if (
        (err.response && err.response.status === 401) ||
        (err.response && err.response.status === 403)
      ) {
        dispatch(signOut());
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde.'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente.');
      }
    }
    loadingClose();
  }

  return (
    <div>
      <h4 className="mb-4">Editar Sócio/Representante/Avalista/Procurador</h4>
      {pendencieIdentified &&
        pendencies.map((p) => {
          if (p.type === 'member' && p.member_id === member.id) {
            return (
              <Alert severity="error" key={p.id} className="mb-2">
                <h6>{p.title}</h6>
                <p>{p.description}</p>
              </Alert>
            );
          }
          return '';
        })}
      <form onSubmit={handleSubmit}>
        <fieldset className="mb-4">
          <FormControl
            style={{ width: '100%' }}
            disabled={verifyDisabled('type')}
          >
            <InputLabel id="type_label">Tipo de Sócio</InputLabel>
            <Select
              style={{ width: '100%' }}
              required
              value={values.type || ''}
              onChange={(e) =>
                setValues({
                  ...values,
                  type: e.target.value,
                })
              }
              labelId="type_label"
              error={
                errors.type !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('type'))
              }
            >
              <MenuItem value={1}>Sócio</MenuItem>
              <MenuItem value={2}>Representante</MenuItem>
              <MenuItem value={3}>Avalista</MenuItem>
              <MenuItem value={4}>Procurador</MenuItem>
            </Select>
            {errors.type !== '' && (
              <FormHelperText error>{errors.type}</FormHelperText>
            )}
          </FormControl>
        </fieldset>
        <fieldset>
          <legend className="mb-3">Dados Básicos</legend>
          <div className="wrap_fields">
            <TextField
              required
              label="Nome Completo"
              value={values.fullname}
              onChange={(e) =>
                setValues({ ...values, fullname: e.target.value })
              }
              disabled={verifyDisabled('fullname')}
              error={
                errors.fullname !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('fullname'))
              }
              helperText={errors.fullname}
            />
            <TextField
              required
              label="Data de Nascimento"
              value={values.date_of_birth}
              onChange={(e) =>
                setValues({
                  ...values,
                  date_of_birth: normalizeDate(e.target.value),
                })
              }
              disabled={verifyDisabled('date_of_birth')}
              error={
                errors.date_of_birth !== '' ||
                (end === 1 &&
                  status !== 'A' &&
                  !verifyDisabled('date_of_birth'))
              }
              helperText={errors.date_of_birth}
            />
            <TextField
              required
              label="CPF"
              value={values.cpf}
              onChange={(e) =>
                setValues({ ...values, cpf: normalizeCpf(e.target.value) })
              }
              disabled={verifyDisabled('cpf')}
              error={
                errors.cpf !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('cpf'))
              }
              helperText={errors.cpf}
            />
            <FormControl
              style={{ width: '100%' }}
              disabled={verifyDisabled('e_cpf')}
            >
              <InputLabel id="e_cpf_label">Possui e-CPF?</InputLabel>
              <Select
                style={{ width: '100%' }}
                required
                value={values.e_cpf}
                onChange={(e) =>
                  setValues({
                    ...values,
                    e_cpf: e.target.value,
                  })
                }
                labelId="e_cpf_label"
                error={
                  errors.e_cpf !== '' ||
                  (end === 1 && status !== 'A' && !verifyDisabled('e_cpf'))
                }
              >
                <MenuItem value="sim">Sim</MenuItem>
                <MenuItem value="nao">Não</MenuItem>
              </Select>
              {errors.e_cpf !== '' && (
                <FormHelperText error>{errors.e_cpf}</FormHelperText>
              )}
            </FormControl>
            <TextField
              required
              label="Número do RG"
              value={values.identity_doc}
              onChange={(e) =>
                setValues({ ...values, identity_doc: e.target.value })
              }
              disabled={verifyDisabled('identity_doc')}
              error={
                errors.identity_doc !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('identity_doc'))
              }
              helperText={errors.identity_doc}
            />
            <TextField
              required
              label="Órgão Emissor"
              value={values.issuing_agency}
              onChange={(e) =>
                setValues({ ...values, issuing_agency: e.target.value })
              }
              disabled={verifyDisabled('issuing_agency')}
              error={
                errors.issuing_agency !== '' ||
                (end === 1 &&
                  status !== 'A' &&
                  !verifyDisabled('issuing_agency'))
              }
              helperText={errors.issuing_agency}
            />
            <StateSelect
              required
              label="Estado do RG"
              value={values.issuing_agency_uf}
              onChange={(e) =>
                setValues({ ...values, issuing_agency_uf: e.target.value })
              }
              disabled={verifyDisabled('issuing_agency_uf')}
              error={
                errors.issuing_agency_uf !== '' ||
                (end === 1 &&
                  status !== 'A' &&
                  !verifyDisabled('issuing_agency_uf'))
              }
              helperText={errors.issuing_agency_uf}
            />
            <TextField
              required
              label="Nacionalidade"
              value={values.nationality}
              onChange={(e) =>
                setValues({ ...values, nationality: e.target.value })
              }
              disabled={verifyDisabled('nationality')}
              error={
                errors.nationality !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('nationality'))
              }
              helperText={errors.nationality}
            />
            <TextField
              required
              label="Profissão"
              value={values.profession}
              onChange={(e) =>
                setValues({ ...values, profession: e.target.value })
              }
              disabled={verifyDisabled('profession')}
              error={
                errors.profession !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('profession'))
              }
              helperText={errors.profession}
            />
            <TextField
              required
              label="Nome da Mãe"
              value={values.mother_name}
              onChange={(e) =>
                setValues({ ...values, mother_name: e.target.value })
              }
              disabled={verifyDisabled('mother_name')}
              error={
                errors.mother_name !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('mother_name'))
              }
              helperText={errors.mother_name}
            />
            <FormControl
              style={{ width: '100%' }}
              disabled={verifyDisabled('in_politically_exposed_person')}
            >
              <InputLabel id="public_person_label">Pessoa pública?</InputLabel>
              <Select
                style={{ width: '100%' }}
                required
                value={values.in_politically_exposed_person}
                onChange={(e) =>
                  setValues({
                    ...values,
                    in_politically_exposed_person: e.target.value,
                  })
                }
                error={
                  errors.in_politically_exposed_person !== '' ||
                  (end === 1 &&
                    status !== 'A' &&
                    !verifyDisabled('in_politically_exposed_person'))
                }
                labelId="public_person_label"
              >
                <MenuItem value="sim">Sim</MenuItem>
                <MenuItem value="nao">Não</MenuItem>
              </Select>
              {errors.in_politically_exposed_person !== '' && (
                <FormHelperText error>
                  {errors.in_politically_exposed_person}
                </FormHelperText>
              )}
            </FormControl>
          </div>
        </fieldset>
        <fieldset>
          <legend className="mb-3">Endereço</legend>
          <div className="wrap_fields">
            <TextField
              required
              label="CEP"
              value={values.zipcode}
              onBlur={handleCep}
              onChange={(e) =>
                setValues({ ...values, zipcode: normalizeCep(e.target.value) })
              }
              disabled={verifyDisabled('zipcode')}
              error={
                errors.zipcode !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('zipcode'))
              }
              helperText={errors.zipcode}
            />
            <TextField
              required
              label="Logradouro"
              value={values.address}
              onChange={(e) =>
                setValues({ ...values, address: e.target.value })
              }
              disabled={verifyDisabled('address')}
              error={
                errors.address !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('address'))
              }
              helperText={errors.address}
            />
            <TextField
              required
              label="Número"
              value={values.number}
              onChange={(e) =>
                setValues({
                  ...values,
                  number: normalizeNumber(e.target.value),
                })
              }
              disabled={verifyDisabled('number')}
              error={
                errors.number !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('number'))
              }
              helperText={errors.number}
            />
            <TextField
              label="Complemento"
              value={values.complement}
              onChange={(e) =>
                setValues({ ...values, complement: e.target.value })
              }
              disabled={verifyDisabled('complement')}
              error={
                end === 1 && status !== 'A' && !verifyDisabled('complement')
              }
            />
            <TextField
              required
              label="Bairro"
              value={values.neighborhood}
              onChange={(e) =>
                setValues({ ...values, neighborhood: e.target.value })
              }
              disabled={verifyDisabled('neighborhood')}
              error={
                errors.neighborhood !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('neighborhood'))
              }
              helperText={errors.neighborhood}
            />
            <TextField
              required
              label="Cidade"
              value={values.city}
              onChange={(e) => setValues({ ...values, city: e.target.value })}
              disabled={verifyDisabled('city')}
              error={
                errors.city !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('neighborhood'))
              }
              helperText={errors.city}
            />
            <StateSelect
              required
              label="Estado"
              value={values.uf}
              onChange={(e) => setValues({ ...values, uf: e.target.value })}
              disabled={verifyDisabled('uf')}
              error={
                errors.uf !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('uf'))
              }
              helperText={errors.uf}
            />
          </div>
        </fieldset>
        <fieldset>
          <legend className="mb-3">Dados de Contato</legend>
          <div className="wrap_fields">
            <TextField
              required
              type="email"
              label="Email"
              value={values.email}
              onChange={(e) => setValues({ ...values, email: e.target.value })}
              disabled={verifyDisabled('email')}
              error={
                errors.email !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('email'))
              }
              helperText={errors.email}
            />
            <TextField
              required
              label="Telefone"
              value={values.phone}
              onChange={(e) =>
                setValues({ ...values, phone: normalizePhone(e.target.value) })
              }
              disabled={verifyDisabled('phone')}
              error={
                errors.phone !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('phone'))
              }
              helperText={errors.phone}
            />
          </div>
        </fieldset>
        <fieldset>
          <legend className="mb-3">Dados de Relacionamento</legend>
          <div className="wrap_fields">
            <FormControl
              style={{ width: '100%' }}
              disabled={verifyDisabled('marital_status')}
            >
              <InputLabel id="civil_state_label">Estado civil</InputLabel>
              <Select
                style={{ width: '100%' }}
                labelId="civil_state_label"
                value={values.marital_status}
                onChange={(e) => {
                  if (
                    e.target.value === 'Casado(a)' &&
                    values.wedding_registry !== 'Separação Total de Bens'
                  ) {
                    setValues({
                      ...values,
                      in_stable_union: 'nao',
                      marital_status: e.target.value,
                    });
                  } else {
                    setValues({
                      ...values,
                      marital_status: e.target.value,
                    });
                  }
                }}
                required
                error={
                  errors.marital_status !== '' ||
                  (end === 1 &&
                    status !== 'A' &&
                    !verifyDisabled('marital_status'))
                }
              >
                <MenuItem value="Solteiro(a)">Solteiro(a)</MenuItem>
                <MenuItem value="Casado(a)">Casado(a)</MenuItem>
                <MenuItem value="Separado(a)/Divorciado(a)">
                  Separado(a)/Divorciado(a)
                </MenuItem>
                <MenuItem value="Viúvo(a)">Viúvo(a)</MenuItem>
              </Select>
              {errors.marital_status !== '' && (
                <FormHelperText error>{errors.marital_status}</FormHelperText>
              )}
            </FormControl>
            <FormControl
              style={{ width: '100%' }}
              disabled={verifyDisabled('in_stable_union_label')}
            >
              <InputLabel id="in_stable_union_label">
                Possui união estável?
              </InputLabel>
              <Select
                style={{ width: '100%' }}
                required
                value={
                  values.marital_status === 'Casado(a)' &&
                  values.wedding_registry !== 'Separação Total de Bens'
                    ? 'nao'
                    : values.in_stable_union
                }
                disabled={
                  values.marital_status === 'Casado(a)' &&
                  values.wedding_registry !== 'Separação Total de Bens'
                }
                onChange={(e) =>
                  setValues({
                    ...values,
                    in_stable_union: e.target.value,
                  })
                }
                error={
                  errors.in_stable_union !== '' ||
                  (end === 1 &&
                    status !== 'A' &&
                    !verifyDisabled('in_stable_union'))
                }
                labelId="in_stable_union_label"
              >
                <MenuItem value="sim">Sim</MenuItem>
                <MenuItem value="nao">Não</MenuItem>
              </Select>
              {errors.in_stable_union !== '' && (
                <FormHelperText error>{errors.in_stable_union}</FormHelperText>
              )}
            </FormControl>
            {values.marital_status === 'Casado(a)' && (
              <FormControl
                style={{ width: '100%' }}
                disabled={verifyDisabled('wedding_registry')}
              >
                <InputLabel id="regime_civil_state_label">
                  Regime do estado civil
                </InputLabel>
                <Select
                  style={{ width: '100%' }}
                  labelId="regime_civil_state_label"
                  value={
                    values.marital_status === 'Casado(a)'
                      ? values.wedding_registry
                      : ''
                  }
                  onChange={(e) => {
                    if (e.target.value !== 'Separação Total de Bens') {
                      setValues({
                        ...values,
                        in_stable_union: 'nao',
                        wedding_registry: e.target.value,
                      });
                    } else {
                      setValues({
                        ...values,
                        wedding_registry: e.target.value,
                      });
                    }
                  }}
                  error={
                    errors.wedding_registry !== '' ||
                    (end === 1 &&
                      status !== 'A' &&
                      !verifyDisabled('wedding_registry'))
                  }
                  required
                >
                  <MenuItem value="Comunhão de Bens">Comunhão de Bens</MenuItem>
                  <MenuItem value="Comunhão Parcial de Bens">
                    Comunhão Parcial de Bens
                  </MenuItem>
                  <MenuItem value="Separação Total de Bens">
                    Separação Total de Bens
                  </MenuItem>
                </Select>
                {errors.wedding_registry && (
                  <FormHelperText error>
                    {errors.wedding_registry}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          </div>
        </fieldset>
        {values.marital_status === 'Casado(a)' &&
          values.wedding_registry !== '' &&
          values.wedding_registry !== 'Separação Total de Bens' && (
            <fieldset>
              <legend className="mb-3">Dados do Cônjuge</legend>
              <div className="wrap_fields">
                <TextField
                  required
                  label="Nome Completo"
                  value={valuesSpouses.fullname}
                  onChange={(e) =>
                    setValuesSpouses({
                      ...valuesSpouses,
                      fullname: e.target.value,
                    })
                  }
                  disabled={verifyDisabledSpouses('fullname')}
                  error={
                    errorsSpouses.fullname !== '' ||
                    (end === 1 &&
                      status !== 'A' &&
                      !verifyDisabledSpouses('fullname'))
                  }
                  helperText={errorsSpouses.fullname}
                />
                <TextField
                  required
                  label="Data de Nascimento"
                  value={valuesSpouses.date_of_birth}
                  onChange={(e) =>
                    setValuesSpouses({
                      ...valuesSpouses,
                      date_of_birth: normalizeDate(e.target.value),
                    })
                  }
                  disabled={verifyDisabledSpouses('date_of_birth')}
                  error={
                    errorsSpouses.date_of_birth !== '' ||
                    (end === 1 &&
                      status !== 'A' &&
                      !verifyDisabledSpouses('date_of_birth'))
                  }
                  helperText={errorsSpouses.date_of_birth}
                />
                <TextField
                  required
                  label="CPF"
                  value={valuesSpouses.cpf}
                  onChange={(e) =>
                    setValuesSpouses({
                      ...valuesSpouses,
                      cpf: normalizeCpf(e.target.value),
                    })
                  }
                  disabled={verifyDisabledSpouses('cpf')}
                  error={
                    errorsSpouses.cpf !== '' ||
                    (end === 1 &&
                      status !== 'A' &&
                      !verifyDisabledSpouses('date_of_birth'))
                  }
                  helperText={errorsSpouses.cpf}
                />
                <FormControl
                  style={{ width: '100%' }}
                  disabled={verifyDisabledSpouses('e_cpf')}
                >
                  <InputLabel id="e_cpf_label-spouses">
                    Possui e-CPF?
                  </InputLabel>
                  <Select
                    style={{ width: '100%' }}
                    required
                    value={valuesSpouses.e_cpf}
                    onChange={(e) =>
                      setValuesSpouses({
                        ...valuesSpouses,
                        e_cpf: e.target.value,
                      })
                    }
                    labelId="e_cpf_label-spouses"
                    error={
                      errorsSpouses.e_cpf !== '' ||
                      (end === 1 &&
                        status !== 'A' &&
                        !verifyDisabledSpouses('e_cpf'))
                    }
                  >
                    <MenuItem value="1">Sim</MenuItem>
                    <MenuItem value="0">Não</MenuItem>
                  </Select>
                  {errorsSpouses.e_cpf && (
                    <FormHelperText error>{errorsSpouses.e_cpf}</FormHelperText>
                  )}
                </FormControl>
                <TextField
                  required
                  label="Número do RG"
                  value={valuesSpouses.identity}
                  onChange={(e) =>
                    setValuesSpouses({
                      ...valuesSpouses,
                      identity: e.target.value,
                    })
                  }
                  disabled={verifyDisabledSpouses('identity')}
                  error={
                    errorsSpouses.identity !== '' ||
                    (end === 1 &&
                      status !== 'A' &&
                      !verifyDisabledSpouses('identity'))
                  }
                  helperText={errorsSpouses.identity}
                />
                <TextField
                  required
                  label="Órgão Emissor"
                  value={valuesSpouses.identity_oe}
                  onChange={(e) =>
                    setValuesSpouses({
                      ...valuesSpouses,
                      identity_oe: e.target.value,
                    })
                  }
                  disabled={verifyDisabledSpouses('identity_oe')}
                  error={
                    errorsSpouses.identity_oe !== '' ||
                    (end === 1 &&
                      status !== 'A' &&
                      !verifyDisabledSpouses('identity_oe'))
                  }
                  helperText={errorsSpouses.identity_oe}
                />
                <StateSelect
                  required
                  label="Estado do RG"
                  value={valuesSpouses.identity_uf}
                  onChange={(e) =>
                    setValuesSpouses({
                      ...valuesSpouses,
                      identity_uf: e.target.value,
                    })
                  }
                  disabled={verifyDisabledSpouses('identity_uf')}
                  error={
                    errorsSpouses.identity_uf !== '' ||
                    (end === 1 &&
                      status !== 'A' &&
                      !verifyDisabledSpouses('identity_uf'))
                  }
                  helperText={errorsSpouses.identity_uf}
                />
                <TextField
                  required
                  label="Profissão"
                  value={valuesSpouses.profession}
                  onChange={(e) =>
                    setValuesSpouses({
                      ...valuesSpouses,
                      profession: e.target.value,
                    })
                  }
                  disabled={verifyDisabledSpouses('profession')}
                  error={
                    errorsSpouses.profession !== '' ||
                    (end === 1 &&
                      status !== 'A' &&
                      !verifyDisabledSpouses('profession'))
                  }
                  helperText={errorsSpouses.profession}
                />
                <TextField
                  required
                  type="email"
                  label="Email"
                  value={valuesSpouses.email}
                  onChange={(e) =>
                    setValuesSpouses({
                      ...valuesSpouses,
                      email: e.target.value,
                    })
                  }
                  disabled={verifyDisabledSpouses('email')}
                  error={
                    errorsSpouses.email !== '' ||
                    (end === 1 &&
                      status !== 'A' &&
                      !verifyDisabledSpouses('email'))
                  }
                  helperText={errorsSpouses.email}
                />
                <TextField
                  required
                  type="text"
                  label="Telefone"
                  value={valuesSpouses.phone}
                  onChange={(e) =>
                    setValuesSpouses({
                      ...valuesSpouses,
                      phone: normalizePhone(e.target.value),
                    })
                  }
                  disabled={verifyDisabledSpouses('phone')}
                  error={
                    errorsSpouses.phone !== '' ||
                    (end === 1 &&
                      status !== 'A' &&
                      !verifyDisabledSpouses('phone'))
                  }
                  helperText={errorsSpouses.phone}
                />
              </div>
            </fieldset>
          )}
        <div className="mt-3">
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={end === 1 ? !pendencieIdentified : false}
          >
            Salvar
          </Button>
          <Button
            type="button"
            color="primary"
            variant="outlined"
            className="ml-2"
            onClick={() => resetForm()}
          >
            Cancelar
          </Button>
        </div>
      </form>
    </div>
  );
}

export default memo(EditMemberPF);
