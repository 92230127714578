import React, { useState, useEffect } from 'react';

import { CircularProgress } from '@material-ui/core';
import { Lock, Error, Menu, Close } from '@material-ui/icons';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Container } from './styles';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Geral from './components/Geral';
import ECnpj from './components/ECnpj';
import Banks from './components/Banks';
import Member from './components/Member';
import References from './components/References';
import Documents from './components/Documents';
import Finalize from './components/Finalize';

import { updateStatus } from '../../../store/modules/company/actions';
import { signOut } from '../../../store/modules/auth/actions';

function Register() {
  const dispatch = useDispatch();
  const { end, pendencies } = useSelector((state) => state.company);
  const [current, setCurrent] = useState(1);
  const [loading, setLoading] = useState(false);
  const [razaoSocial, setRazaoSocial] = useState('');

  const [open, setOpen] = useState(false);

  async function loadEnded() {
    setLoading(true);
    try {
      const { data } = await axios.get('/customer-register/documents');
      const { data: dataPendencies } = await axios.get(
        '/customer-register/pendencies'
      );
      const { data: members } = await axios.get('/customer-register/members');
      setRazaoSocial(data.razao_social);
      dispatch(
        updateStatus({
          pendencies: dataPendencies.pendencies,
          status:
            data.finished === 'A' && !members.find((m) => m.finished !== 'A')
              ? 'A'
              : 'S',
          end: data.end,
        })
      );
    } catch (err) {
      if (
        (err.response && err.response.status === 401) ||
        (err.response && err.response.status === 403)
      ) {
        dispatch(signOut());
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde.'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente.');
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    loadEnded();
  }, []); // eslint-disable-line

  return (
    <>
      <Header />
      <Container className="container-fluid">
        <button
          type="button"
          id="burger"
          onClick={() => setOpen(!open)}
          className={open ? 'open' : ''}
        >
          {open ? <Close /> : <Menu />}
        </button>
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <strong className="blue_light">{razaoSocial}</strong>
          </div>
          <div id="register_area">
            <div id="sub_menu" className={open ? 'open' : ''}>
              <button
                type="button"
                onClick={() => setCurrent(1)}
                className={current === 1 ? 'active' : ''}
              >
                Informações Gerais{' '}
                {end === 1 &&
                  (pendencies.find(
                    (i) =>
                      i.type === 'company' &&
                      !i.data.bancos &&
                      !i.data.referencias_comerciais
                  ) ? (
                    <Error color="secondary" style={{ fontSize: '14px' }} />
                  ) : (
                    <Lock style={{ fontSize: '14px' }} />
                  ))}
              </button>
              <button
                type="button"
                onClick={() => setCurrent(2)}
                className={current === 2 ? 'active' : ''}
              >
                e-CNPJ
              </button>
              <button
                type="button"
                onClick={() => setCurrent(3)}
                className={current === 3 ? 'active' : ''}
              >
                Contas Bancárias{' '}
                {end === 1 &&
                  (pendencies.find(
                    (i) => i.type === 'company' && i.data.bancos
                  ) ? (
                    <Error color="secondary" style={{ fontSize: '14px' }} />
                  ) : (
                    <Lock style={{ fontSize: '14px' }} />
                  ))}
              </button>
              <button
                type="button"
                onClick={() => setCurrent(4)}
                className={current === 4 ? 'active' : ''}
              >
                Sócios / Representantes / Avalistas / Procuradores{' '}
                {end === 1 &&
                  (pendencies.find((i) => i.type === 'member') ? (
                    <Error color="secondary" style={{ fontSize: '14px' }} />
                  ) : (
                    <Lock style={{ fontSize: '14px' }} />
                  ))}
              </button>
              <button
                type="button"
                onClick={() => setCurrent(5)}
                className={current === 5 ? 'active' : ''}
              >
                Referências Financeiras <br />
                (fundos / factorings / bancos)
                {end === 1 &&
                  (pendencies.find(
                    (i) => i.type === 'company' && i.data.referencias_comerciais
                  ) ? (
                    <Error color="secondary" style={{ fontSize: '14px' }} />
                  ) : (
                    <Lock style={{ fontSize: '14px' }} />
                  ))}
              </button>
              <button
                type="button"
                onClick={() => setCurrent(6)}
                className={current === 6 ? 'active' : ''}
              >
                Documentos{' '}
                {end === 1 &&
                  (pendencies.find((i) => i.type === 'document') ? (
                    <Error color="secondary" style={{ fontSize: '14px' }} />
                  ) : (
                    <Lock style={{ fontSize: '14px' }} />
                  ))}
              </button>
              {end === 0 && (
                <button
                  type="button"
                  onClick={() => setCurrent(7)}
                  className={current === 7 ? 'active' : ''}
                >
                  Finalizar Cadastro
                </button>
              )}
            </div>
            <div id="register_data">
              {loading && (
                <div id="loading_section">
                  <CircularProgress color="primary" size={80} />
                </div>
              )}
              {current === 1 && (
                <Geral
                  loadingShow={() => setLoading(true)}
                  loadingClose={() => setLoading(false)}
                />
              )}
              {current === 2 && (
                <ECnpj
                  loadingShow={() => setLoading(true)}
                  loadingClose={() => setLoading(false)}
                />
              )}
              {current === 3 && (
                <Banks
                  loadingShow={() => setLoading(true)}
                  loadingClose={() => setLoading(false)}
                />
              )}
              {current === 4 && (
                <Member
                  loadingShow={() => setLoading(true)}
                  loadingClose={() => setLoading(false)}
                />
              )}
              {current === 5 && (
                <References
                  loadingShow={() => setLoading(true)}
                  loadingClose={() => setLoading(false)}
                />
              )}
              {current === 6 && (
                <Documents
                  loadingShow={() => setLoading(true)}
                  loadingClose={() => setLoading(false)}
                />
              )}
              {current === 7 && (
                <Finalize
                  loadingShow={() => setLoading(true)}
                  loadingClose={() => setLoading(false)}
                />
              )}
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default Register;
