import React, { useState, useEffect } from 'react';

import { Close, Warning, Error, InfoOutlined } from '@material-ui/icons';
import { Button, Modal, TextField } from '@material-ui/core';

import axios from 'axios';
import { toast } from 'react-toastify';

import { useSelector, useDispatch } from 'react-redux';
import NewMemberPF from './components/NewMemberPF';
import NewMemberPJ from './components/NewMemberPJ';

import EditMemberPF from './components/EditMemberPF';
import EditMemberPJ from './components/EditMemberPJ';

import { normalizeCnpj, normalizeCpf } from '../utils/normalize';
import { validarCNPJ, validarCPF } from '../utils/memberValidation';

import { signOut } from '../../../../../store/modules/auth/actions';

function Member({ loadingShow, loadingClose }) {
  const dispatch = useDispatch();
  const { pendencies, end, status } = useSelector((state) => state.company);
  const [members, setMembers] = useState([]);
  const [newMember, setNewMember] = useState({
    active: false,
    type: null,
  });

  const [defineDocument, setDefineDocument] = useState({
    type: null,
    value: '',
    id: null,
    error: '',
  });

  const [deleteActive, setDeleteActive] = useState({ open: false, id: null });

  const [edit, setEdit] = useState({ active: false, member: {} });

  async function loadRegister() {
    loadingShow();
    try {
      const response = await axios.get('/customer-register/members');
      setMembers(response.data);
    } catch (err) {
      if (
        (err.response && err.response.status === 401) ||
        (err.response && err.response.status === 403)
      ) {
        dispatch(signOut());
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde.'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente.');
      }
    }
    loadingClose();
  }

  useEffect(() => {
    loadRegister();
  }, []); // eslint-disable-line

  async function handleDelete() {
    loadingShow();
    const { id } = deleteActive;
    try {
      await axios.delete(`/customer-register/members/${id}`);
      setMembers(members.filter((m) => m.id !== id));
      setDeleteActive({ open: false, id: null });
    } catch (err) {
      if (
        (err.response && err.response.status === 401) ||
        (err.response && err.response.status === 403)
      ) {
        dispatch(signOut());
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde.'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente.');
      }
    }
    loadingClose();
  }

  function handleUpdateMember() {
    loadRegister();
  }

  async function handleUpdateDocument() {
    setDefineDocument({
      ...defineDocument,
      error: '',
    });
    if (defineDocument.type === 'PF') {
      if (!validarCPF(defineDocument.value)) {
        return setDefineDocument({
          ...defineDocument,
          error: 'CPF inválido',
        });
      }
      if (
        members.find(
          (m) => m.id !== defineDocument.id && m.cpf === defineDocument.value
        )
      ) {
        return setDefineDocument({
          ...defineDocument,
          error: 'CPF duplicado',
        });
      }
    } else if (defineDocument.type === 'PJ') {
      if (!validarCNPJ(defineDocument.value)) {
        return setDefineDocument({
          ...defineDocument,
          error: 'CNPJ inválido',
        });
      }
      if (
        members.find(
          (m) => m.id !== defineDocument.id && m.cpf === defineDocument.value
        )
      ) {
        return setDefineDocument({
          ...defineDocument,
          error: 'CNPJ duplicado',
        });
      }
    }
    loadingShow();
    try {
      await axios.put(
        `/customer-register/members/${defineDocument.id}/document`,
        {
          cpf: defineDocument.value,
        }
      );
      setDefineDocument({
        type: null,
        value: '',
        id: null,
        error: '',
      });
      const response = await axios.get('/customer-register/members');
      setMembers(response.data);
    } catch (err) {
      if (
        (err.response && err.response.status === 401) ||
        (err.response && err.response.status === 403)
      ) {
        dispatch(signOut());
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde.'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente.');
      }
    }
    return loadingClose();
  }

  return (
    <div>
      <Modal
        open={deleteActive.open}
        onClose={() => setDeleteActive({ open: false, id: null })}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="d-flex justify-content-center align-items-center"
      >
        <div
          style={{
            position: 'absolute',
            width: '100%',
            maxWidth: '500px',
            backgroundColor: '#fff',
            padding: '30px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Warning
            style={{ fontSize: '50px' }}
            color="secondary"
            className="mb-4"
          />
          <p>Você tem certeza que deseja deletar este Membro?</p>
          <strong>Esta ação não pode ser revertida</strong>
          <div className="mt-4">
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              onClick={() => handleDelete()}
            >
              Sim, excluir
            </Button>
            <Button
              type="button"
              variant="contained"
              color="secondary"
              className="ml-2"
              onClick={() => setDeleteActive({ open: false, id: null })}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>
      {!newMember.active && !edit.active ? (
        <div>
          <h4 className="mb-4">
            Sócios / Representantes / Avalistas / Procuradores
          </h4>
          {members.find((m) => m.cpf === null) && (
            <p>
              <InfoOutlined
                style={{ color: '#444', fontSize: '20px' }}
                className="mr-2"
              />
              Encontramos estes sócios na base da dados da Receita Federal.
              Qualquer dado desatualizado fique avontade para excluir ou
              atualizar.
            </p>
          )}
          <div id="grid_list" className="mt-2">
            {members.map((m, index) => (
              <div key={index} className="grid_list_item">
                {m.type !== null && (
                  <div className="label_header">
                    {m.type === 1 && 'Sócio'}
                    {m.type === 2 && 'Representante'}
                    {m.type === 3 && 'Avalista'}
                    {m.type === 4 && 'Procurador'}
                  </div>
                )}
                {end === 0 && (
                  <button
                    type="button"
                    className="delete_button"
                    onClick={() => {
                      setDeleteActive({
                        open: true,
                        id: m.id,
                      });
                    }}
                  >
                    <Close />
                  </button>
                )}
                {pendencies.find((i) => i.member_id === m.id) &&
                  status !== 'A' && (
                    <button
                      type="button"
                      className="delete_button"
                      onClick={() => {}}
                    >
                      <Error />
                    </button>
                  )}
                <div className="descript">
                  <p>
                    <strong>Nome:</strong> {m.fullname}
                  </p>
                  {m.cpf !== null && (
                    <p>
                      <strong>Documento:</strong> {m.cpf}
                    </p>
                  )}
                  {m.phone !== null && (
                    <p>
                      <strong>Telefone:</strong> {m.phone}
                    </p>
                  )}
                  {m.email !== null && (
                    <p>
                      <strong>E-mail:</strong> {m.email}
                    </p>
                  )}
                  {m.cpf !== null && status !== 'A' && (
                    <Button
                      type="button"
                      color={
                        pendencies.find((i) => i.member_id === m.id)
                          ? 'secondary'
                          : 'primary'
                      }
                      variant="outlined"
                      onClick={() =>
                        setEdit({
                          active: true,
                          member: m,
                        })
                      }
                    >
                      {end === 0 && 'Editar'}
                      {end === 1 &&
                        (pendencies.find((i) => i.member_id === m.id)
                          ? 'Resolver Pendêcias'
                          : 'Ver')}
                    </Button>
                  )}
                  {m.cpf === null &&
                    (defineDocument.id === m.id ? (
                      <div>
                        <TextField
                          label={defineDocument.type === 'PF' ? 'CPF' : 'CNPJ'}
                          value={defineDocument.value}
                          onChange={(e) =>
                            setDefineDocument({
                              ...defineDocument,
                              value:
                                defineDocument.type === 'PF'
                                  ? normalizeCpf(e.target.value)
                                  : normalizeCnpj(e.target.value),
                            })
                          }
                          error={defineDocument.error !== ''}
                          helperText={defineDocument.error}
                          style={{ width: '100%' }}
                        />
                        <div className="d-flex justify-content-between align-items-center mt-2">
                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={handleUpdateDocument}
                          >
                            Salvar
                          </Button>
                          <Button
                            type="button"
                            variant="outlined"
                            color="primary"
                            className="ml-2"
                            onClick={() =>
                              setDefineDocument({
                                id: null,
                                type: null,
                                value: '',
                                error: '',
                              })
                            }
                          >
                            Cancelar
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center align-items-center">
                        <Button
                          type="button"
                          variant="outlined"
                          color="primary"
                          onClick={() =>
                            setDefineDocument({
                              id: m.id,
                              type: 'PF',
                              value: '',
                              error: '',
                            })
                          }
                        >
                          Pessoa <br />
                          Física
                        </Button>
                        <Button
                          type="button"
                          variant="outlined"
                          className="ml-2"
                          color="primary"
                          onClick={() =>
                            setDefineDocument({
                              id: m.id,
                              type: 'PJ',
                              value: '',
                              error: '',
                            })
                          }
                        >
                          Pessoa <br />
                          Jurídica
                        </Button>
                      </div>
                    ))}
                </div>
              </div>
            ))}
            {end === 0 && (
              <div className="grid_list_item detached">
                <Button
                  type="button"
                  onClick={() => {
                    setNewMember({
                      active: true,
                      type: 'PF',
                    });
                  }}
                  color="primary"
                >
                  <p>Adicionar Sócio PF</p>
                  <span>Clique aqui para adicionar um sócio Pessoa Física</span>
                </Button>
                <Button
                  type="button"
                  onClick={() => {
                    setNewMember({
                      active: true,
                      type: 'PJ',
                    });
                  }}
                  color="primary"
                >
                  <p>Adicionar Sócio PJ</p>
                  <span>
                    Clique aqui para adicionar um sócio Pessoa Jurídica
                  </span>
                </Button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          {newMember.active && (
            <>
              {newMember.type === 'PF' && (
                <NewMemberPF
                  resetForm={() =>
                    setNewMember({
                      active: false,
                      type: null,
                    })
                  }
                  loadingShow={() => loadingShow()}
                  loadingClose={() => loadingClose()}
                  addMember={() => loadRegister()}
                />
              )}
              {newMember.type === 'PJ' && (
                <NewMemberPJ
                  resetForm={() =>
                    setNewMember({
                      active: false,
                      type: null,
                    })
                  }
                  loadingShow={() => loadingShow()}
                  loadingClose={() => loadingClose()}
                  addMember={() => loadRegister()}
                />
              )}
            </>
          )}
          {edit.active && (
            <>
              {edit.member && edit.member.cpf && (
                <>
                  {edit.member.cpf.length === 14 && (
                    <EditMemberPF
                      resetForm={() =>
                        setEdit({
                          active: false,
                          member: {},
                        })
                      }
                      loadingShow={() => loadingShow()}
                      loadingClose={() => loadingClose()}
                      updateMember={() => handleUpdateMember()}
                      member={edit.member}
                    />
                  )}
                  {edit.member.cpf.length > 14 && (
                    <EditMemberPJ
                      resetForm={() =>
                        setEdit({
                          active: false,
                          member: {},
                        })
                      }
                      loadingShow={() => loadingShow()}
                      loadingClose={() => loadingClose()}
                      updateMember={() => handleUpdateMember()}
                      member={edit.member}
                    />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Member;
