import React, { memo } from 'react';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';

const StateSelect = ({
  value,
  onChange,
  label = 'Estado',
  error,
  helperText,
  required = false,
  disabled = false,
}) => {
  return (
    <FormControl
      style={{ width: '100%' }}
      error={error}
      required={required}
      disabled={disabled}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        style={{ width: '100%' }}
        value={value}
        onChange={(e) => onChange(e)}
        required={required}
        error={error}
        disabled={disabled}
      >
        <MenuItem value="AC">Acre</MenuItem>
        <MenuItem value="AL">Alagoas</MenuItem>
        <MenuItem value="AP">Amapá</MenuItem>
        <MenuItem value="AM">Amazonas</MenuItem>
        <MenuItem value="BA">Bahia</MenuItem>
        <MenuItem value="CE">Ceará</MenuItem>
        <MenuItem value="DF">Distrito Federal</MenuItem>
        <MenuItem value="ES">Espírito Santo</MenuItem>
        <MenuItem value="GO">Goiás</MenuItem>
        <MenuItem value="MA">Maranhão</MenuItem>
        <MenuItem value="MT">Mato Grosso</MenuItem>
        <MenuItem value="MS">Mato Grosso do Sul</MenuItem>
        <MenuItem value="MG">Minas Gerais</MenuItem>
        <MenuItem value="PA">Pará</MenuItem>
        <MenuItem value="PB">Paraíba</MenuItem>
        <MenuItem value="PR">Paraná</MenuItem>
        <MenuItem value="PE">Pernambuco</MenuItem>
        <MenuItem value="PI">Piauí</MenuItem>
        <MenuItem value="RJ">Rio de Janeiro</MenuItem>
        <MenuItem value="RN">Rio Grande do Norte</MenuItem>
        <MenuItem value="RS">Rio Grande do Sul</MenuItem>
        <MenuItem value="RO">Rondônia</MenuItem>
        <MenuItem value="RR">Roraima</MenuItem>
        <MenuItem value="SC">Santa Catarina</MenuItem>
        <MenuItem value="SP">São Paulo</MenuItem>
        <MenuItem value="SE">Sergipe</MenuItem>
        <MenuItem value="TO">Tocantis</MenuItem>
      </Select>
      {helperText !== '' && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default memo(StateSelect);
