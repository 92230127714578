import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  div#auth_container {
    width: 100%;
    max-width: 400px;
    background: #fff;
    padding: 35px;
    h2 {
      color: #3757a1;
      font-size: 1.5rem;
    }
    img {
      width: 50px;
    }
    a {
      display: block;
      margin-top: 15px;
      text-align: center;
    }
  }
`;
