export function updateStatus({ pendencies, status, end }) {
  return {
    type: '@company/SET_STATUS',
    payload: { pendencies, status, end },
  };
}

export function updatePendencie({ pendencies }) {
  return {
    type: '@company/UPDATE_PENDENCIE',
    payload: { pendencies },
  };
}

export function updatePendencieRequest() {
  return {
    type: '@company/UPDATE_PENDENCIE_REQUEST',
  };
}
