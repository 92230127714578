import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { Error, Warning, InfoOutlined } from '@material-ui/icons';
import { Button, Modal } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import validationCompany from '../utils/companyValidation';
import { validationPFOff, validationPJOff } from '../utils/memberValidation';

import { signOut } from '../../../../../store/modules/auth/actions';

import verifyTitle from '../utils/verifyTitle';

function Finalize({ loadingShow, loadingClose }) {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState([]);
  const [warning, setWarning] = useState([]);
  const [verified, setVerified] = useState(false);

  const [confirm, setConfirm] = useState(false);

  useEffect(() => {
    async function analyzeRegister() {
      loadingShow();
      try {
        const lock_errors = [];
        const lock_warning = [];
        const { data: company } = await axios.get('/customer-register');
        const { data: members } = await axios.get('/customer-register/members');
        const {
          data: { documents },
        } = await axios.get('/customer-register/documents');
        const { data: references } = await axios.get(
          '/customer-register/financial-references'
        );
        const { data: banks } = await axios.get('/customer-register/banks');

        const errors_company = validationCompany(company);
        const keys_errors_company = Object.keys(errors_company);
        for (let i = 0; i < keys_errors_company.length; i += 1) {
          if (errors_company[keys_errors_company[i]] !== '') {
            lock_errors.push({
              field: verifyTitle(keys_errors_company[i]),
            });
          }
        }

        if (members.length === 0) {
          lock_warning.push({
            message:
              'Nenhum sócio/representante/avalista/procuradores cadastrado, cadastre no mínimo 1.',
            type: 'Representante / Sócio / Avalistas / Procuradores',
          });
        } else if (!members.find((m) => m.type === 2)) {
          lock_warning.push({
            message: 'Necessário cadastrar no mínimo 1 Representante',
            type: 'Representante / Sócio / Avalistas / Procuradores',
          });
        } else {
          members.forEach((member) => {
            if (member.cpf === null) {
              lock_warning.push({
                message: `Complete ou Remova o cadastro de ${member.fullname}`,
                type: 'Representante / Sócio / Avalistas / Procuradores',
              });
            } else if (member.cpf.length === 14) {
              const errors_member_lock = validationPFOff(
                member,
                member.memberSpouses
              );
              const keys_error_member = Object.keys(errors_member_lock.errors);
              let count_error_member = 0;
              for (let c = 0; c < keys_error_member.length; c += 1) {
                if (errors_member_lock.errors[keys_error_member[c]] !== '') {
                  count_error_member += 1;
                }
              }
              if (count_error_member > 0) {
                lock_warning.push({
                  message: `Complete o Cadastro de ${member.fullname}`,
                  type: 'Representante / Sócio / Avalistas / Procuradores',
                });
              }
            } else if (member.cpf.length > 14) {
              const errors_member_lock = validationPJOff(
                member,
                member.memberSpouses
              );
              const keys_error_member = Object.keys(errors_member_lock);
              let count_error_member = 0;
              for (let c = 0; c < keys_error_member.length; c += 1) {
                if (errors_member_lock[keys_error_member[c]] !== '') {
                  count_error_member += 1;
                }
              }
              if (count_error_member > 0) {
                lock_warning.push({
                  message: `Complete o Cadastro de ${member.fullname}`,
                  type: 'Representante / Sócio / Avalistas / Procuradores',
                });
              }
            }
          });
        }

        if (references.length === 0) {
          lock_warning.push({
            message: 'Cadastre no mínimo 1',
            type: 'Referências Financeiras',
          });
        }

        if (banks.length === 0) {
          lock_warning.push({
            message: 'Cadastre no mínimo 1',
            type: 'Contas Bancárias',
          });
        }

        if (!documents.find((d) => d.status !== null)) {
          lock_warning.push({
            message: 'Nenhum documento enviado',
            type: 'Documentos',
          });
        }

        setErrors(lock_errors);
        setWarning(lock_warning);
        setVerified(true);
      } catch (err) {
        if (
          (err.response && err.response.status === 401) ||
          (err.response && err.response.status === 403)
        ) {
          dispatch(signOut());
        } else if (err.response && err.response.status === 500) {
          toast.error(
            'Ocorreu um erro em nossos servidores, tente novamente mais tarde.'
          );
        } else {
          toast.error('Ocorreu um erro, tente novamente.');
        }
      }
      loadingClose();
    }
    analyzeRegister();
  }, []); // eslint-disable-line

  async function finishRegister() {
    try {
      await axios.put('/customer-register/finished');
      setConfirm(false);
      document.location.reload();
    } catch (err) {
      if (
        (err.response && err.response.status === 401) ||
        (err.response && err.response.status === 403)
      ) {
        dispatch(signOut());
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde.'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente.');
      }
    }
  }

  return (
    <div>
      <Modal
        open={confirm}
        onClose={() => setConfirm(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="d-flex justify-content-center align-items-center"
      >
        <div
          style={{
            position: 'absolute',
            width: '100%',
            maxWidth: '500px',
            backgroundColor: '#fff',
            padding: '30px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Warning
            style={{ fontSize: '50px' }}
            color="secondary"
            className="mb-4"
          />
          <p>Você tem certeza que deseja finalizar seu cadastro?</p>
          <div className="mt-4">
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              onClick={() => finishRegister()}
            >
              Sim, finalizar
            </Button>
            <Button
              type="button"
              variant="contained"
              color="secondary"
              className="ml-2"
              onClick={() => setConfirm(false)}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>
      {warning.length > 0 || errors.length > 0 ? (
        <>
          <h4 style={{ textAlign: 'center' }}>
            Seu cadastro ainda não está apto para ser finalizado.
          </h4>

          <div id="grid_analyze">
            <div className="warning_place">
              {warning.length > 0 &&
                warning.map((w, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Warning className="mr-2" style={{ color: 'orange' }} />
                    <div>
                      <strong>{w.type}</strong>
                      <p>{w.message}</p>
                    </div>
                  </div>
                ))}
            </div>
            <div className="errors_place">
              {errors.length > 0 &&
                errors.map((e, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Error className="mr-2" color="secondary" />
                    <div>
                      <strong>{e.field} [Informações Gerais]</strong>
                      <p>Campo vazio ou incorreto</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </>
      ) : (
        verified && (
          <div
            className="d-flex justify-content-center align-items-center text-center"
            style={{ flexDirection: 'column' }}
          >
            <InfoOutlined
              color="primary"
              style={{ fontSize: '60px' }}
              className="mb-4"
            />
            <h4>Deseja realmente finalizar seu cadastro?</h4>
            <p>
              Depois de finalizado seu cadatro, será feito uma análise de seus
              dados e documentos. Qualquer pendência relacionada aos dados ou
              documentos serão retornadas para retificação, ficando disponível
              para edição apenas os campos recusados.
            </p>
            <p>
              OBS: A área do e-CNPJ <strong>sempre</strong> ficará disponível
              para edição.
            </p>
            <div className="d-flex">
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={() => setConfirm(true)}
              >
                Sim, finalizar
              </Button>
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default Finalize;
