import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0%, 100% {
    transform: scale(1);
  }50% {
    transform: scale(1.4);
  }
`;

export const Container = styled.div`
  background: #3757a1;
  border-bottom: 4px solid #7ed0ff;
  padding: 15px 10px;
  span.status_register {
    font-weight: bold;
    color: #fff;
    padding: 5px 25px;
    margin-right: 10px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 1px;
      height: 70%;
      background: #b3b3b3;
      border-radius: 2px;
    }
    &.blue_bar {
      &::before {
        background: #7ed0ff;
      }
    }
    &.green_bar {
      &::before {
        background: #42f587;
      }
    }
    &.red_bar {
      &::before {
        background: #dc3545;
      }
    }
  }
  img {
    width: 100%;
    max-width: 70px;
    margin-right: 20px;
    padding-right: 20px;
    border-right: 1px solid #fff;
  }
  span {
    display: block;
  }
  button {
    color: #fff;
    &:hover {
      color: #7ed0ff;
    }
    &:focus {
      box-shadow: none !important;
    }
    &.pulled {
      animation: ${pulse} 5s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
    }
  }
  div.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    position: relative;
  }
  @media screen and (max-width: 550px) {
    > div.container {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
`;

export const Notification = styled.div`
  position: absolute;
  width: 100%;
  max-width: 300px;
  padding: 7.5px;
  background: #7ed0ff;
  color: #222;
  top: 80px;
  right: 0px;
  display: none;
  z-index: 20;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  &.notification_open {
    display: block;
  }
  h5 {
    font-size: 16px;
    color: #3757a1;
  }
  div#notifications_list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    max-height: 275px;
    overflow-y: hidden;
    div.notification_item {
      padding: 10px;
      background: rgba(255, 255, 255, 0.3);
      strong {
        margin: 0 0 5px;
        font-size: 14px;
        display: block;
        color: #333;
        font-weight: 700;
      }
      p {
        margin: 0;
        font-size: 13px;
        line-height: 1.25;
        color: #313131;
      }
    }
  }
`;
