import React, { useState, useEffect } from 'react';

import { format } from 'date-fns';

import { Button, Modal } from '@material-ui/core';
import { Close, VpnKey, Warning } from '@material-ui/icons';
import axios from 'axios';

import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { normalizeCnpj } from '../utils/normalize';
import { signOut } from '../../../../../store/modules/auth/actions';

import AddEcnpj from './components/AddEcnpj';

function ECnpj({ loadingShow, loadingClose }) {
  const dispatch = useDispatch();
  const [eCnpjs, setECnpjs] = useState([]);
  const [deleteActive, setDeleteActive] = useState({ open: false, id: null });

  async function loadECnpjs() {
    loadingShow();
    try {
      const { data } = await axios.get('/customer-register/e-cnpj');
      setECnpjs(data);
    } catch (err) {
      if (
        (err.response && err.response.status === 401) ||
        (err.response && err.response.status === 403)
      ) {
        dispatch(signOut());
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde.'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente.');
      }
    }
    loadingClose();
  }
  useEffect(() => {
    loadECnpjs();
  }, []); // eslint-disable-line

  async function handleDelete() {
    const { id } = deleteActive;
    loadingShow();
    try {
      await axios.delete(`customer-register/e-cnpj/${id}`);
      setECnpjs(eCnpjs.filter((i) => i.id !== id));
      setDeleteActive({
        open: false,
        id: null,
      });
    } catch (err) {
      if (
        (err.response && err.response.status === 401) ||
        (err.response && err.response.status === 403)
      ) {
        dispatch(signOut());
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde.'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente.');
      }
    }
    loadingClose();
  }

  return (
    <div>
      <Modal
        open={deleteActive.open}
        onClose={() => setDeleteActive({ open: false, id: null })}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="d-flex justify-content-center align-items-center"
      >
        <div
          style={{
            position: 'absolute',
            width: '100%',
            maxWidth: '500px',
            backgroundColor: '#fff',
            padding: '30px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Warning
            style={{ fontSize: '50px' }}
            color="secondary"
            className="mb-4"
          />
          <p>Você tem certeza que deseja deletar este e-CNPJ?</p>
          <strong>Esta ação não pode ser revertida</strong>
          <div className="mt-4">
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              onClick={() => handleDelete()}
            >
              Sim, excluir
            </Button>
            <Button
              type="button"
              variant="contained"
              color="secondary"
              className="ml-2"
              onClick={() => setDeleteActive({ open: false, id: null })}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>
      <h4>Envie o(s) seu(s) e-CNPJ para analisarmos os seus recebíveis.</h4>
      <p className="mb-4">
        OBS: Assim que instalado(s), podem ser removidos{' '}
        <b>a qualquer momento</b>.
      </p>
      <AddEcnpj
        onAdd={loadECnpjs}
        loadingClose={loadingClose}
        loadingShow={loadingShow}
      />
      {eCnpjs.length > 0 && (
        <div className="box-eCnpj-list">
          <h4>e-CNPJ instalados</h4>
          <div id="grid_ecnpj">
            {eCnpjs.map((e, index) => (
              <div className="grid_item" key={index}>
                <div>
                  <small>{normalizeCnpj(e.cnpj)}</small>
                  <small>
                    {format(new Date(e.created_at), "dd/MM/yyyy 'às' HH:mm")}
                  </small>
                </div>
                <div className="d-flex align-items-cente justify-content-center">
                  <strong>
                    <VpnKey size={12} /> {e.name}
                  </strong>
                  <button
                    type="button"
                    onClick={() => setDeleteActive({ open: true, id: e.id })}
                  >
                    <Close />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default ECnpj;
