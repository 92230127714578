import styled from 'styled-components';

export const Container = styled.div`
  margin: 30px 0px;
  #burger {
    display: none;
  }
  > div.container {
    background: rgba(255, 255, 255, 0.99);
    padding: 0px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    min-height: 75vh;
    strong.blue_light {
      color: #666;
      font-size: 24px;
    }
    div#register_area {
      display: grid;
      grid-template-columns: 1.5fr 6.5fr;
      position: relative;
      div#loading_section {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        z-index: 20;
      }
      div#sub_menu {
        background: #f9f9f9;
        button {
          display: block;
          width: 100%;
          padding: 20px 10px;
          font-size: 14px;
          border: none;
          background: #f9f9f9;
          color: #848484;
          -webkit-transition: 300ms;
          transition: 300ms;
          text-align: center;
          &.active {
            background: #fff;
            color: #333;
          }
          &:hover {
            background: #f1f1f1;
          }
        }
        button + button {
          border-top: 1px solid #e4e4e4;
        }
      }
      div#register_data {
        height: 100%;
        min-height: 400px;
        overflow: auto;
        padding: 30px;
        div#grid_analyze {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 30px;
          div.warning_place {
            div {
              width: 100%;
              margin: 15px 0px;
              strong {
                color: orange;
              }
            }
          }
          div.errors_place {
            div {
              width: 100%;
              margin: 15px 0px;
              strong {
                color: #dc3545;
              }
            }
          }
          strong {
            display: block;
          }
          p {
            margin: 0;
          }
        }
        fieldset {
          margin: 0 0 40px;
          legend {
            margin: 0px;
            font-size: 16px;
          }
          div.wrap_fields {
            padding: 0;
            margin: 0;
            margin-top: 8px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 10px;
            div.MuiFormControl-root {
              width: 100%;
            }
          }
        }
        div.box_e-cnpj {
          padding: 30px;
          border: 1px solid #c9c9c9;
        }
        input[type='file'] {
          display: none;
        }
        label.label_file {
          padding: 20px;
          border: 2px dashed #7ed0ff;
          display: block;
          border-radius: 6px;
          cursor: pointer;
          color: #7ed0ff;
          font-weight: bolder;
          text-align: center;
        }
        div#grid_list {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 40px;
          .grid_list_item {
            position: relative;
            background: #f9f9f9;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            font-size: 16px;
            div.descript {
              padding: 20px;
              p {
                margin-bottom: 10px;
                font-size: 14px;
              }
              button {
                margin-top: 10px;
                width: 100%;
              }
            }
            div.label_header {
              background: #3757a1;
              color: #ffffff;
              padding: 15px;
              text-align: center;
            }
            button.delete_button {
              position: absolute;
              top: -10px;
              right: -10px;
              width: 25px;
              height: 25px;
              background: #3756a1;
              box-shadow: -1px 1px 0px 1px #fff;
              color: #fff;
              border: none;
              border-radius: 50%;
              margin: 0;
              svg {
                font-size: 17px;
                margin-top: -5px;
              }
            }
            button {
              margin-top: 10px;
            }
            &.detached {
              padding: 0;
              border: 2px dashed #e2e2e2;
              min-height: 200px;
              button {
                display: flex;
                flex: 1;
                margin: 0;
                padding: 0 20px;
                > span {
                  display: flex;
                  flex-direction: column;
                  padding: 0 10px;
                  p {
                    font-size: 14px;
                    font-weight: 600;
                  }
                  span {
                    font-size: 11px;
                    text-transform: none;
                    padding: 0 10px;
                    line-height: 1.6;
                    color: #828282;
                  }
                }
              }
            }
          }
        }
        .step_current {
          transition: 300ms;
          font-size: 16px;
          color: #666;
          &.active {
            color: #3757a1;
            font-size: 32px;
          }
        }
        .box_dropzone {
          padding: 25px;
          background: #f9f9f9;
          border-radius: 6px;
          border: 2px dashed #7ed0ff;
          text-align: center;
          margin-top: 20px;
        }
        .thumb__container {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
          .thumbInner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 10px;
            span {
              color: #666;
              font-size: 14px;
              font-weight: bold;
            }
            button {
              background: none;
              border: none;
            }
          }
          .thumbInner + .thumbInner {
            border-top: 1px solid #000;
          }
        }
        .step_current + .step_current {
          margin-left: 20px;
        }
        .MuiFormControlLabel-root {
          margin: 0;
        }
        .MuiTypography-body1 {
          font-size: 12px;
        }
        .MuiIconButton-label {
          * {
            font-size: 16px;
          }
        }
        .box-eCnpj-list {
          padding: 20px;
          margin-top: 20px;
          background: #f9f9f9;
          text-align: center;
          h4 {
            color: #3757a1;
            font-size: 20px;
          }
          h5 {
            color: #666;
            font-size: 16px;
          }
          div#grid_ecnpj {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 10px;
            margin-top: 25px;
            div.grid_item {
              padding: 15px;
              background: #7ed0ff;
              color: #3757a1;
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              small {
                display: block;
                text-align: left;
              }
              button {
                background: none;
                border: none;
                margin-left: 10px;
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
  .MuiFormHelperText-root {
    font-size: 11px;
  }
  @media screen and (max-width: 900px) {
    #burger {
      display: flex;
      position: fixed;
      bottom: 100px;
      right: 20px;
      z-index: 50;
      color: #444;
      padding: 20px;
      border-radius: 50%;
      background: #7ed0ff;
      border: none;
      transition: 500ms;
    }
    > div.container {
      div#register_area {
        grid-template-columns: 1fr;
        div#sub_menu {
          position: fixed;
          height: 100vh;
          z-index: 30;
          padding-top: 80px;
          top: 0px;
          left: 0px;
          transition: 500ms;
          transform: translateX(-100%);
          &.open {
            transform: translateX(0);
          }
        }
        div#register_data {
          div#grid_analyze {
            grid-template-columns: 1fr;
          }
          div#grid_list {
            grid-template-columns: 1fr;
          }
          fieldset {
            div.wrap_fields {
              grid-template-columns: 1fr;
            }
          }
        }
      }
    }
  }
`;
