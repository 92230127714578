import React, { useState, useEffect } from 'react';

import { Close, Warning } from '@material-ui/icons';
import { Button, TextField, Modal } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import axios from 'axios';

import { useSelector, useDispatch } from 'react-redux';

import { toast } from 'react-toastify';
import { normalizePhone } from '../utils/normalize';

import { updatePendencieRequest } from '../../../../../store/modules/company/actions';
import { signOut } from '../../../../../store/modules/auth/actions';

function References({ loadingShow, loadingClose }) {
  const dispatch = useDispatch();
  const { pendencies, end, status } = useSelector((state) => state.company);
  const [deleteActive, setDeleteActive] = useState({
    open: false,
    id: null,
  });
  const [references, setReferences] = useState([]);
  const [newReference, setNewReference] = useState({
    active: false,
    empresa: '',
    tipo_negocio: '',
    telefone: '',
  });
  const [newReferenceError, setNewReferenceError] = useState({
    empresa: '',
    tipo_negocio: '',
    telefone: '',
  });
  const [edit, setEdit] = useState({
    index: null,
    empresa: '',
    tipo_negocio: '',
    telefone: '',
  });
  const [editError, setEditError] = useState({
    empresa: '',
    tipo_negocio: '',
    telefone: '',
  });

  const [pendencieIdentified, setPendencieIdentified] = useState(false);
  const [pendenciesMessages, setPendenciesMessages] = useState([]);

  useEffect(() => {
    async function loadRegister() {
      loadingShow();
      try {
        const response = await axios.get(
          '/customer-register/financial-references'
        );
        setReferences(response.data);
        if (end === 1 && status !== 'A') {
          const lock_messages = [];
          pendencies.forEach((p) => {
            if (p.type === 'company' && p.data.referencias_comerciais) {
              lock_messages.push({
                title: p.title,
                description: p.description,
              });
              if (!pendencieIdentified) {
                setPendencieIdentified(true);
              }
            } else if (pendencieIdentified) {
              setPendencieIdentified(false);
            }
          });
          setPendenciesMessages(lock_messages);
        }
      } catch (err) {
        if (
          (err.response && err.response.status === 401) ||
          (err.response && err.response.status === 403)
        ) {
          dispatch(signOut());
        } else if (err.response && err.response.status === 500) {
          toast.error(
            'Ocorreu um erro em nossos servidores, tente novamente mais tarde.'
          );
        } else {
          toast.error('Ocorreu um erro, tente novamente.');
        }
      }
      loadingClose();
    }
    loadRegister();
  }, [pendencies]); // eslint-disable-line

  async function handleAddReference() {
    setNewReferenceError({
      empresa: '',
      tipo_negocio: '',
      telefone: '',
    });
    const lock_errors = {
      empresa: '',
      tipo_negocio: '',
      telefone: '',
    };
    let count = 0;
    if (newReference.empresa === '') {
      lock_errors.empresa = 'Preencha esse campo';
      count += 1;
    }
    if (newReference.tipo_negocio === '') {
      lock_errors.tipo_negocio = 'Preencha esse campo';
      count += 1;
    }
    if (newReference.telefone === '') {
      lock_errors.telefone = 'Preencha esse campo';
      count += 1;
    }
    if (count > 0) {
      return setNewReferenceError(lock_errors);
    }
    loadingShow();
    try {
      const companyData = await axios.get('/customer-register');
      const bankData = await axios.get('/customer-register/banks');
      await axios.put(`/customer-register`, {
        ...companyData.data,
        bancos: bankData.data,
        referencias_comerciais: [...references, newReference],
      });
      setReferences([...references, newReference]);
      setNewReference({
        active: false,
        empresa: '',
        tipo_negocio: '',
        telefone: '',
      });
      toast.success('Referência adicionada com sucesso');
    } catch (err) {
      if (
        (err.response && err.response.status === 401) ||
        (err.response && err.response.status === 403)
      ) {
        dispatch(signOut());
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde.'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente.');
      }
    }
    return loadingClose();
  }

  async function handleResolvePendencie() {
    loadingShow();
    try {
      const lock_pendencies = pendencies.filter(
        (p) =>
          p.type === 'company' &&
          !p.data.bancos &&
          p.data.referencias_comerciais
      );
      const body_data = [];
      lock_pendencies.forEach((i) => {
        body_data.push({
          id: i.id,
          data: {
            referencias_comerciais: references,
          },
        });
      });
      await axios.put('/customer-register/pendencies', {
        pendencies: body_data,
      });
      toast.success('Pendência resolvida');
      dispatch(updatePendencieRequest());
    } catch (err) {
      if (
        (err.response && err.response.status === 401) ||
        (err.response && err.response.status === 403)
      ) {
        dispatch(signOut());
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde.'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente.');
      }
    }
    return loadingClose();
  }

  async function handleUpdateReference() {
    setEditError({
      empresa: '',
      tipo_negocio: '',
      telefone: '',
    });
    const lock_errors = {
      empresa: '',
      tipo_negocio: '',
      telefone: '',
    };
    let count = 0;
    if (edit.empresa === '') {
      lock_errors.empresa = 'Preencha esse campo';
      count += 1;
    }
    if (edit.tipo_negocio.length < 4) {
      lock_errors.tipo_negocio = 'Preencha esse campo';
      count += 1;
    }
    if (edit.telefone === '') {
      lock_errors.telefone = 'Preencha esse campo';
      count += 1;
    }
    if (count > 0) {
      return setEditError(lock_errors);
    }
    const allReferences = references;
    allReferences[edit.index] = {
      empresa: edit.empresa,
      tipo_negocio: edit.tipo_negocio,
      telefone: edit.telefone,
    };
    if (pendencieIdentified) {
      return handleResolvePendencie();
    }
    loadingShow();
    try {
      await axios.put(`/customer-register/financial-references/${edit.index}`, {
        empresa: edit.empresa,
        tipo_negocio: edit.tipo_negocio,
        telefone: edit.telefone,
      });
      setReferences([...allReferences]);
      setEdit({
        index: null,
        empresa: '',
        tipo_negocio: '',
        telefone: '',
      });
      toast.success('Referência atualizada');
    } catch (err) {
      if (
        (err.response && err.response.status === 401) ||
        (err.response && err.response.status === 403)
      ) {
        dispatch(signOut());
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde.'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente.');
      }
    }
    return loadingClose();
  }

  async function handleRemove() {
    loadingShow();
    const { id: index } = deleteActive;
    try {
      await axios.delete(`/customer-register/financial-references/${index}`);
      setReferences(references.filter((i, ind) => ind !== index));
      setDeleteActive({ open: false, id: null });
      toast.success('Referência removida');
    } catch (err) {
      if (
        (err.response && err.response.status === 401) ||
        (err.response && err.response.status === 403)
      ) {
        dispatch(signOut());
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde.'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente.');
      }
    }
    loadingClose();
  }

  return (
    <div>
      <Modal
        open={deleteActive.open}
        onClose={() => setDeleteActive({ open: false, id: null })}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="d-flex justify-content-center align-items-center"
      >
        <div
          style={{
            position: 'absolute',
            width: '100%',
            maxWidth: '500px',
            backgroundColor: '#fff',
            padding: '30px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <Warning
            style={{ fontSize: '50px' }}
            color="secondary"
            className="mb-4"
          />
          <p>Você tem certeza que deseja deletar esta Referência Financeira?</p>
          <strong>Esta ação não pode ser revertida</strong>
          <div className="mt-4">
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              onClick={() => handleRemove()}
            >
              Sim, excluir
            </Button>
            <Button
              type="button"
              variant="contained"
              color="secondary"
              className="ml-2"
              onClick={() => setDeleteActive({ open: false, id: null })}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>
      <h4 className="mb-4">
        Referências Financeiras (fundos / factorings / bancos)
      </h4>
      {pendenciesMessages.length > 0 &&
        pendenciesMessages.map((m, index) => (
          <Alert key={index} severity="error" className="mb-4">
            <h6>{m.title}</h6>
            <p>{m.description}</p>
          </Alert>
        ))}
      <div id="grid_list">
        {references.map((b, index) => {
          if (edit.index === index) {
            return (
              <div
                className="grid_list_item"
                key={index}
                style={{ padding: '30px' }}
              >
                <TextField
                  label="Empresa"
                  value={edit.empresa}
                  className="mb-2"
                  onChange={(e) =>
                    setEdit({
                      ...edit,
                      empresa: e.target.value,
                    })
                  }
                  error={editError.empresa !== ''}
                  helperText={editError.empresa}
                />
                <TextField
                  label="Tipo de Negócio"
                  value={edit.tipo_negocio}
                  className="mb-2"
                  onChange={(e) =>
                    setEdit({
                      ...edit,
                      tipo_negocio: e.target.value,
                    })
                  }
                  error={editError.tipo_negocio !== ''}
                  helperText={editError.tipo_negocio}
                />
                <TextField
                  label="Telefone"
                  value={edit.telefone}
                  className="mb-2"
                  onChange={(e) =>
                    setEdit({
                      ...edit,
                      telefone: normalizePhone(e.target.value),
                    })
                  }
                  error={editError.telefone !== ''}
                  helperText={editError.telefone}
                />
                <div className="d-flex mt-4">
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={handleUpdateReference}
                  >
                    Salvar
                  </Button>
                  <Button
                    type="button"
                    variant="outlined"
                    color="primary"
                    className="ml-2"
                    onClick={() => {
                      setEdit({
                        index: null,
                        banco: '',
                        tipo_negocio: '',
                        telefone: '',
                      });
                      setEditError({
                        banco: '',
                        tipo_negocio: '',
                        telefone: '',
                      });
                    }}
                  >
                    Cancelar
                  </Button>
                </div>
              </div>
            );
          }
          if (pendencieIdentified) {
            return (
              <div
                className="grid_list_item"
                key={index}
                style={{ padding: '30px' }}
              >
                <TextField
                  label="Empresa"
                  value={b.empresa}
                  className="mb-2"
                  onChange={(e) => {
                    const all_references = references;
                    all_references[index].empresa = e.target.value;
                    setReferences([...all_references]);
                  }}
                  error={b.empresa === ''}
                  helperText={b.empresa === '' && 'Preencha esse campo'}
                />
                <TextField
                  label="Tipo de Negócio"
                  value={b.tipo_negocio}
                  className="mb-2"
                  onChange={(e) => {
                    const all_references = references;
                    all_references[index].tipo_negocio = e.target.value;
                    setReferences([...all_references]);
                  }}
                  error={b.tipo_negocio === ''}
                  helperText={b.tipo_negocio === '' && 'Preencha esse campo'}
                />
                <TextField
                  label="Telefone"
                  value={b.telefone}
                  className="mb-2"
                  onChange={(e) => {
                    const all_references = references;
                    all_references[index].telefone = normalizePhone(
                      e.target.value
                    );
                    setReferences([...all_references]);
                  }}
                  error={b.telefone === ''}
                  helperText={
                    (b.telefone === '' || b.telefone.length < 14) &&
                    'Preencha esse campo'
                  }
                />
              </div>
            );
          }
          return (
            <div key={index} className="grid_list_item">
              <div className="label_header">Referência #{index + 1}</div>
              {end === 0 && (
                <button
                  type="button"
                  className="delete_button"
                  onClick={() =>
                    setDeleteActive({
                      open: true,
                      id: index,
                    })
                  }
                >
                  <Close />
                </button>
              )}
              <div className="descript">
                <p>
                  <strong>Empresa: </strong>
                  {b.empresa}
                </p>
                <p>
                  <strong>Tipo de Negócio: </strong>
                  {b.tipo_negocio}
                </p>
                <p>
                  <strong>Telefone: </strong>
                  {b.telefone}
                </p>
                {(end === 0 || pendencieIdentified) && (
                  <Button
                    type="button"
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      setEdit({
                        index,
                        empresa: b.empresa,
                        tipo_negocio: b.tipo_negocio,
                        telefone: b.telefone,
                      });
                    }}
                  >
                    {pendencieIdentified ? 'Resolver Pendência' : 'Editar'}
                  </Button>
                )}
              </div>
            </div>
          );
        })}
        {newReference.active ? (
          <div className="grid_list_item" style={{ padding: '10px 20px 20px' }}>
            <TextField
              label="Empresa"
              value={newReference.empresa}
              className="mb-2"
              onChange={(e) =>
                setNewReference({
                  ...newReference,
                  empresa: e.target.value,
                })
              }
              error={newReferenceError.empresa !== ''}
              helperText={newReferenceError.empresa}
              required
            />
            <TextField
              label="Tipo de Negócio"
              value={newReference.tipo_negocio}
              className="mb-2"
              onChange={(e) =>
                setNewReference({
                  ...newReference,
                  tipo_negocio: e.target.value,
                })
              }
              error={newReferenceError.tipo_negocio !== ''}
              helperText={newReferenceError.tipo_negocio}
              required
            />
            <TextField
              label="Telefone"
              value={newReference.telefone}
              className="mb-2"
              onChange={(e) =>
                setNewReference({
                  ...newReference,
                  telefone: normalizePhone(e.target.value),
                })
              }
              error={newReferenceError.telefone !== ''}
              helperText={newReferenceError.telefone}
              required
            />
            <div className="d-flex mt-4">
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={handleAddReference}
              >
                Salvar
              </Button>
              <Button
                type="button"
                variant="outlined"
                color="primary"
                className="ml-2"
                onClick={() => {
                  setNewReference({
                    active: false,
                    empresa: '',
                    tipo_negocio: '',
                    telefone: '',
                  });
                  setNewReferenceError({
                    empresa: '',
                    tipo_negocio: '',
                    telefone: '',
                  });
                }}
              >
                Cancelar
              </Button>
            </div>
          </div>
        ) : (
          end === 0 && (
            <div className="grid_list_item detached">
              <Button
                type="button"
                color="primary"
                onClick={() => {
                  setNewReference({
                    active: true,
                    empresa: '',
                    tipo_negocio: '',
                    telefone: '',
                  });
                }}
              >
                <p>Adicionar Referência Financeira</p>
                <span>
                  Clique aqui para adicionar uma nova referência financeira
                </span>
              </Button>
            </div>
          )
        )}
      </div>
      {pendencieIdentified && (
        <Button
          type="butotn"
          color="secondary"
          variant="outlined"
          onClick={handleResolvePendencie}
          disabled={references.find(
            (b) =>
              b.empresa === '' ||
              b.tipo_negocio === '' ||
              b.telefone === '' ||
              b.telefone.length < 14
          )}
        >
          Resolver Pendências
        </Button>
      )}
    </div>
  );
}

export default References;
