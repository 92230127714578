import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';
import Register from './Register';
import Home from './Home';
import Auth from './Auth';

export default function MainPage() {
  return (
    <Switch>
      <Route exact path="/" component={Register} />
      <Route path="/sign-up" component={Register} />
      <Route path="/sign-in" component={Home} />
      <Route exact path="/auth/:token" component={Auth} />
      <Redirect to="/" />
    </Switch>
  );
}
