export default function verifyTitle(item) {
  switch (item) {
    case 'razao_social':
      return 'Razão Social';
    case 'nome_fantasia':
      return 'Nome Fantasia';
    case 'cep':
      return 'CEP';
    case 'endereco':
      return 'Endereço';
    case 'numero':
      return 'Número';
    case 'bairro':
      return 'Bairro';
    case 'cidade':
      return 'Cidade';
    case 'uf':
      return 'Estado';
    case 'email_empresa':
      return 'Email da Empresa';
    case 'telefone_da_empresa':
      return 'Telefone da Empresa';
    case 'data_fundacao':
      return 'Data de Fundação';
    case 'n_funcionarios':
      return 'Número de Funcionários';
    default:
      return '';
  }
}
