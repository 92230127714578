import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import axios from 'axios';
import { signInSuccess, signOut } from './actions';

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;
    const response = yield call(axios.post, '/customer/auth', {
      email,
      password,
    });

    const { token, customer_name, email_customer } = response.data;

    axios.defaults.headers.common.token = token;

    yield put(
      signInSuccess(token, {
        customer_name,
        email: email_customer,
      })
    );
  } catch (err) {
    toast.error('Falha na autenticação, verifique seus dados');
  }
}

export function* setToken({ payload }) {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  if (!payload) return;
  const { token } = payload.auth;

  if (token) {
    axios.defaults.headers.common.token = token;
    try {
      yield call(axios.get, '/customer-register/customer');
    } catch (err) {
      if (
        (err.response && err.response.status === 401) ||
        (err.response && err.response.status === 403)
      ) {
        yield put(signOut());
      }
    }
  }
}

export function* setTokenSignIn({ payload }) {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  if (!payload) return;
  const { token } = payload;
  if (token) {
    yield (axios.defaults.headers.common.token = token);
  }
}

export function signOutSaga() {
  toast.info('Usuário deslogado');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_IN_SUCCESS', setTokenSignIn),
  takeLatest('@auth/SIGN_OUT', signOutSaga),
]);
