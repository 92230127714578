import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';
import Register from './Register';
import Auth from './Auth';

export default function MainPage() {
  return (
    <Switch>
      <Route exact path="/dashboard" component={Register} />
      <Route exact path="/auth/:token" component={Auth} />
      <Redirect to="/dashboard" />
    </Switch>
  );
}
