import React, { useState, useEffect } from 'react';

import { TextField, Button } from '@material-ui/core';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Alert } from '@material-ui/lab';
import { useSelector, useDispatch } from 'react-redux';
import StateSelect from '../utils/StateSelect';
import {
  normalizeCep,
  normalizeNumber,
  normalizePhone,
  normalizeDate,
  normalizeCnpj,
} from '../utils/normalize';
import validation from '../utils/companyValidation';

import { updatePendencieRequest } from '../../../../../store/modules/company/actions';
import { signOut } from '../../../../../store/modules/auth/actions';

function Geral({ loadingShow, loadingClose }) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const { pendencies, end, status } = useSelector((state) => state.company);
  const [cnpj, setCnpj] = useState('');

  const [pendencieIdentified, setPendencieIdentified] = useState(false);
  const [fieldsPendencies, setFieldsPendencies] = useState([]);

  const [values, setValues] = useState({
    razao_social: '',
    nome_fantasia: '',
    insc_estadual: '',
    cep: '',
    endereco: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    uf: '',
    email_empresa: '',
    telefone_da_empresa: '',
    data_fundacao: '',
    site: '',
    n_funcionarios: '',
  });
  const [errors, setErrors] = useState({
    razao_social: '',
    nome_fantasia: '',
    insc_estadual: '',
    cep: '',
    endereco: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    uf: '',
    email_empresa: '',
    telefone_da_empresa: '',
    data_fundacao: '',
    site: '',
    n_funcionarios: '',
  });

  useEffect(() => {
    async function loadRegister() {
      loadingShow();
      try {
        axios.defaults.headers.common.token = token;
        const response = await axios.get('/customer-register');
        setValues({
          ...response.data,
          cnpj: undefined,
          cep: normalizeCep(response.data.cep),
        });
        setCnpj(normalizeCnpj(response.data.cnpj));
      } catch (err) {
        if (
          (err.response && err.response.status === 401) ||
          (err.response && err.response.status === 403)
        ) {
          dispatch(signOut());
        } else if (err.response && err.response.status === 500) {
          toast.error(
            'Ocorreu um erro em nossos servidores, tente novamente mais tarde.'
          );
        } else {
          toast.error('Ocorreu um erro, tente novamente.');
        }
      }
      loadingClose();
    }
    loadRegister();
  }, []); // eslint-disable-line

  function verifyDisabled(field) {
    if (end === 1) {
      if (status === 'A') {
        return true;
      }
      const pendencie = pendencies.find(
        (i) => i.type === 'company' && i.data[field]
      );
      if (pendencie) {
        if (!fieldsPendencies.find((i) => i === field)) {
          setFieldsPendencies([...fieldsPendencies, field]);
        }
        if (!pendencieIdentified) {
          setPendencieIdentified(true);
        }
        return false;
      }
      return true;
    }
    return false;
  }

  async function handleCep() {
    loadingShow();
    if (values.zipcode && values.zipcode.length === 9) {
      const api = axios.create({
        baseURL: 'https://viacep.com.br/ws',
      });
      delete api.defaults.headers.common.token;
      const response = await api.get(`/${values.cep.replace(/\D/g, '')}/json`);
      if (!response.data.erro) {
        const {
          uf,
          logradouro: endereco,
          bairro,
          localidade: cidade,
        } = response.data;
        setValues({
          ...values,
          uf,
          endereco,
          bairro,
          cidade,
        });
      }
    }
    loadingClose();
  }
  async function handleResolvePendencie() {
    const lock_pendencies = pendencies.filter(
      (p) =>
        p.type === 'company' && !p.data.bancos && !p.data.referencias_comerciais
    );
    const body_data = [];
    lock_pendencies.forEach((i) => {
      const data_send = {};
      for (let c = 0; c < fieldsPendencies.length; c += 1) {
        data_send[fieldsPendencies[c]] = values[fieldsPendencies[c]];
      }
      body_data.push({
        id: i.id,
        data: data_send,
      });
    });
    loadingShow();
    try {
      await axios.put('/customer-register/pendencies', {
        pendencies: body_data,
      });
      toast.success('Pendência resolvida');
      dispatch(updatePendencieRequest());
    } catch (err) {
      if (
        (err.response && err.response.status === 401) ||
        (err.response && err.response.status === 403)
      ) {
        dispatch(signOut());
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde.'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente.');
      }
    }
    return loadingClose();
  }
  async function handleSubmit(e) {
    e.preventDefault();
    setErrors({
      razao_social: '',
      nome_fantasia: '',
      insc_estadual: '',
      cep: '',
      endereco: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      uf: '',
      email_empresa: '',
      telefone_da_empresa: '',
      data_fundacao: '',
      site: '',
      n_funcionarios: '',
    });
    const lock_errors = validation(values);
    setErrors(lock_errors);
    const keys = Object.keys(lock_errors);
    for (let i = 0; i < keys.length; i += 1) {
      if (lock_errors[keys[i]] !== '') {
        return;
      }
    }
    if (pendencieIdentified) {
      handleResolvePendencie();
      return;
    }
    loadingShow();
    try {
      const financialData = await axios.get(
        '/customer-register/financial-references'
      );
      const bankData = await axios.get('/customer-register/banks');
      await axios.put('/customer-register', {
        bancos: bankData.data,
        referencias_comerciais: financialData.data,
        ...values,
      });
      toast.success('Informações Gerais atualizadas');
    } catch (err) {
      if (
        (err.response && err.response.status === 401) ||
        (err.response && err.response.status === 403)
      ) {
        dispatch(signOut());
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde.'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente.');
      }
    }
    loadingClose();
  }

  return (
    <div>
      <h4 className="mb-4">Informações Gerais</h4>
      {pendencieIdentified &&
        pendencies.map((p) => {
          if (
            p.type === 'company' &&
            !p.data.bancos &&
            !p.data.referencias_comerciais
          ) {
            return (
              <Alert severity="error" key={p.id} className="mb-2">
                <h6>{p.title}</h6>
                <p>{p.description}</p>
              </Alert>
            );
          }
          return '';
        })}
      <form onSubmit={handleSubmit}>
        <fieldset>
          <legend className="mb-3">Dados Básicos da Empresa</legend>
          <div className="wrap_fields">
            <TextField label="CNPJ" value={cnpj} disabled />
            <TextField
              label="Razão Social"
              value={values.razao_social}
              required
              onChange={(e) =>
                setValues({ ...values, razao_social: e.target.value })
              }
              disabled={verifyDisabled('razao_social')}
              error={
                errors.razao_social !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('razao_social'))
              }
              helperText={errors.razao_social}
            />
            <TextField
              label="Nome Fantasia"
              value={values.nome_fantasia}
              required
              onChange={(e) =>
                setValues({ ...values, nome_fantasia: e.target.value })
              }
              disabled={verifyDisabled('nome_fantasia')}
              error={
                errors.nome_fantasia !== '' ||
                (end === 1 &&
                  status !== 'A' &&
                  !verifyDisabled('nome_fantasia'))
              }
              helperText={errors.nome_fantasia}
            />
            <TextField
              label="Inscrição Estadual"
              value={values.insc_estadual}
              onChange={(e) =>
                setValues({
                  ...values,
                  insc_estadual: normalizeNumber(e.target.value),
                })
              }
              disabled={verifyDisabled('insc_estadual')}
              error={
                errors.insc_estadual !== '' ||
                (end === 1 &&
                  status !== 'A' &&
                  !verifyDisabled('insc_estadual'))
              }
              helperText={errors.insc_estadual}
            />
            <TextField
              label="Data de Fundação"
              value={values.data_fundacao}
              required
              onChange={(e) =>
                setValues({
                  ...values,
                  data_fundacao: normalizeDate(e.target.value),
                })
              }
              disabled={verifyDisabled('data_fundacao')}
              error={
                errors.data_fundacao !== '' ||
                (end === 1 &&
                  status !== 'A' &&
                  !verifyDisabled('data_fundacao'))
              }
              helperText={errors.data_fundacao}
            />
            <TextField
              label="Número de Funcionários"
              value={values.n_funcionarios}
              onChange={(e) =>
                setValues({
                  ...values,
                  n_funcionarios: normalizeNumber(e.target.value),
                })
              }
              required
              disabled={verifyDisabled('n_funcionarios')}
              error={
                errors.n_funcionarios !== '' ||
                (end === 1 &&
                  status !== 'A' &&
                  !verifyDisabled('n_funcionarios'))
              }
              helperText={errors.n_funcionarios}
            />
          </div>
        </fieldset>
        <fieldset>
          <legend className="mb-3">Endereço</legend>
          <div className="wrap_fields">
            <TextField
              label="CEP"
              value={values.cep}
              onBlur={handleCep}
              required
              onChange={(e) =>
                setValues({ ...values, cep: normalizeCep(e.target.value) })
              }
              disabled={verifyDisabled('cep')}
              error={
                errors.cep !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('cep'))
              }
              helperText={errors.cep}
            />
            <TextField
              label="Endereço"
              value={values.endereco}
              required
              onChange={(e) =>
                setValues({ ...values, endereco: e.target.value })
              }
              disabled={verifyDisabled('endereco')}
              error={
                errors.endereco !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('endereco'))
              }
              helperText={errors.endereco}
            />
            <TextField
              label="Número"
              value={values.numero}
              required
              onChange={(e) =>
                setValues({
                  ...values,
                  numero: normalizeNumber(e.target.value),
                })
              }
              disabled={verifyDisabled('numero')}
              error={
                errors.numero !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('numero'))
              }
              helperText={errors.numero}
            />
            <TextField
              label="Complemento"
              value={values.complemento}
              onChange={(e) =>
                setValues({ ...values, complemento: e.target.value })
              }
              disabled={verifyDisabled('complemento')}
              error={
                errors.complemento !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('complemento'))
              }
              helperText={errors.complemento}
            />
            <TextField
              label="Bairro"
              value={values.bairro}
              required
              onChange={(e) => setValues({ ...values, bairro: e.target.value })}
              disabled={verifyDisabled('bairro')}
              error={
                errors.bairro !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('bairro'))
              }
              helperText={errors.bairro}
            />
            <TextField
              label="Cidade"
              value={values.cidade}
              required
              onChange={(e) => setValues({ ...values, cidade: e.target.value })}
              disabled={verifyDisabled('cidade')}
              error={
                errors.cidade !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('cidade'))
              }
              helperText={errors.cidade}
            />
            <StateSelect
              label="Estado"
              required
              disabled={verifyDisabled('uf')}
              error={
                errors.uf !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('uf'))
              }
              helperText={errors.uf}
              value={values.uf}
              onChange={(e) => setValues({ ...values, uf: e.target.value })}
            />
          </div>
        </fieldset>
        <fieldset>
          <legend className="mb-3">Dados de Contato</legend>
          <div className="wrap_fields">
            <TextField
              type="email"
              label="Email da Empresa"
              value={values.email_empresa}
              required
              onChange={(e) =>
                setValues({ ...values, email_empresa: e.target.value })
              }
              disabled={verifyDisabled('email_empresa')}
              error={
                errors.email_empresa !== '' ||
                (end === 1 &&
                  status !== 'A' &&
                  !verifyDisabled('email_empresa'))
              }
              helperText={errors.email_empresa}
            />
            <TextField
              label="Telefone da Empresa"
              value={values.telefone_da_empresa}
              required
              onChange={(e) =>
                setValues({
                  ...values,
                  telefone_da_empresa: normalizePhone(e.target.value),
                })
              }
              disabled={verifyDisabled('telefone_da_empresa')}
              error={
                errors.telefone_da_empresa !== '' ||
                (end === 1 &&
                  status !== 'A' &&
                  !verifyDisabled('telefone_da_empresa'))
              }
              helperText={errors.telefone_da_empresa}
            />
            <TextField
              label="Site da Empresa"
              value={values.site}
              onChange={(e) => setValues({ ...values, site: e.target.value })}
              disabled={verifyDisabled('site')}
              error={
                errors.site !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('site'))
              }
              helperText={errors.site}
            />
          </div>
        </fieldset>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={end === 1 ? !pendencieIdentified : false}
        >
          Salvar
        </Button>
      </form>
    </div>
  );
}

export default Geral;
