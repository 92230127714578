import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { useSelector } from 'react-redux';
import AuthPage from './pages/auth/AuthPage';
import MainPage from './pages/main/MainPage';

export default function Routes() {
  const { signed } = useSelector((state) => state.auth);
  return (
    <>
      <Switch>
        {!signed ? <AuthPage /> : <MainPage />}
        {!signed ? <Redirect to="/auth" /> : <Redirect to="/" />}
      </Switch>
    </>
  );
}
