import React, { memo, useEffect, useState } from 'react';

import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

import axios from 'axios';

const BankSelect = ({ value, onChange, label = 'Banco', error }) => {
  const [banks, setBanks] = useState([]);
  useEffect(() => {
    async function loadBanks() {
      const { data } = await axios.get('/banks');
      const bank_data = [];
      data.forEach((b) => bank_data.push(`${b.number} - ${b.name}`));
      setBanks(bank_data);
    }
    loadBanks();
  }, []);

  return (
    <Autocomplete
      options={banks}
      getOptionLabel={(option) => option}
      value={value}
      onChange={(e, v) => onChange(v)}
      style={{ width: '100%' }}
      renderInput={(params) => (
        <TextField
          label={label}
          error={error}
          style={{ width: '100%' }}
          {...params} // eslint-disable-line
        />
      )}
    />
  );
};

export default memo(BankSelect);
