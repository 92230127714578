import React, { useState } from 'react';

import { TextField, Button } from '@material-ui/core';
import {
  Visibility,
  VisibilityOff,
  AddCircle,
  HighlightOff,
} from '@material-ui/icons';

import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { normalizeCnpj } from '../../utils/normalize';
import { signOut } from '../../../../../../store/modules/auth/actions';

function AddEcnpj({ onAdd, loadingShow, loadingClose }) {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [cnpjError, setCnpjError] = useState(false);
  const [file, setFile] = useState(null);
  const [values, setValues] = useState({
    cnpj: '',
    password: '',
  });
  const [branchs, setBranchs] = useState(['']);
  const [ecnpjId, setEcnpjId] = useState(null);

  async function handleSubmit() {
    const obj = new FormData();
    obj.append('file', file);
    loadingShow();
    try {
      const { data: cnpj } = await axios.get('/customer-register');
      const cnpj_replaced = cnpj.cnpj.replace(/[^\d]/g, '');
      const cnpj_filial = values.cnpj.replace(/[^\d]/g, '');
      if (cnpj_replaced.slice(0, 8) !== cnpj_filial.slice(0, 8)) {
        loadingClose();
        setCnpjError(true);
        return;
      }
    } catch (err) {
      if (
        (err.response && err.response.status === 401) ||
        (err.response && err.response.status === 403)
      ) {
        dispatch(signOut());
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde.'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente.');
      }
    }
    loadingClose();
    loadingShow();
    try {
      await axios.post(`/customer-register/e-cnpj`, obj, {
        headers: {
          'Content-Type': 'multipart/form-data',
          cnpj: values.cnpj.replace(/[^\d]/g, ''),
          password: values.password,
        },
      });
      const { data } = await axios.get('/customer-register/e-cnpj');
      setEcnpjId(data[data.length - 1].id);
      onAdd();
      setStep(2);
    } catch (err) {
      if (
        (err.response && err.response.status === 401) ||
        (err.response && err.response.status === 403)
      ) {
        dispatch(signOut());
      } else if (err.response && err.response.status === 400) {
        toast.error(err.response.data.msg);
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde.'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente.');
      }
    }
    loadingClose();
  }

  function handleCancel() {
    setBranchs(['']);
    setFile(null);
    setEcnpjId(null);
    setValues({
      cnpj: '',
      password: '',
    });
    setVisiblePassword(false);
    setCnpjError(false);
    setStep(1);
  }

  async function handleAdd() {
    loadingShow();
    try {
      await axios.post(`/customer-register/e-cnpj/associate/${ecnpjId}`, {
        branchs: branchs.map((b) => {
          if (b.length === 7) {
            return b.replace(/[^\d]/g, '');
          }
          return '';
        }),
      });
      handleCancel();
      onAdd();
    } catch (err) {
      if (
        (err.response && err.response.status === 401) ||
        (err.response && err.response.status === 403)
      ) {
        dispatch(signOut());
      } else if (err.response && err.response.status === 400) {
        toast.error(err.response.data.msg);
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde.'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente.');
      }
    }
    loadingClose();
  }

  function handleChangeByIndex(value, index) {
    const valuesBranchs = branchs.map((b, ind) => {
      if (ind === index) {
        return value.length > 4
          ? `${value.slice(0, 4)}-${value.slice(4, 6)}`
          : value;
      }
      return b;
    });
    setBranchs(valuesBranchs);
  }

  return (
    <div>
      {file === null ? (
        <label htmlFor="e-CNPJ" className="label_file">
          Escolher arquivo
          <input
            type="file"
            id="e-CNPJ"
            onChange={(e) => setFile(e.target.files[0])}
            accept=".pfx"
          />
        </label>
      ) : (
        <>
          {step === 1 && (
            <div className="box_e-cnpj">
              <p>
                Informe o CNPJ que está relacionado ao arquivo (
                <u>{file.name}</u>) e a senha.
              </p>
              <TextField
                label="CNPJ"
                value={values.cnpj}
                onChange={(e) =>
                  setValues({ ...values, cnpj: normalizeCnpj(e.target.value) })
                }
                error={cnpjError}
                helperText={cnpjError && 'CNPJ não corresponde a esta empresa'}
                style={{ width: '100%' }}
              />
              <div className="d-flex">
                <TextField
                  label="Senha"
                  value={values.password}
                  type={visiblePassword ? 'text' : 'password'}
                  onChange={(e) =>
                    setValues({ ...values, password: e.target.value })
                  }
                  style={{ width: '100%' }}
                />
                <button
                  type="button"
                  className="btn btn-sm"
                  onClick={() => setVisiblePassword(!visiblePassword)}
                >
                  {visiblePassword ? <Visibility /> : <VisibilityOff />}
                </button>
              </div>
              <div className="d-flex mt-4">
                <Button
                  color="primary"
                  variant="contained"
                  className="mr-2"
                  disabled={
                    values.cnpj.length < 18 || values.password.length === 0
                  }
                  onClick={handleSubmit}
                >
                  Enviar
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    setFile(null);
                    setValues({
                      cnpj: '',
                      password: '',
                    });
                    setVisiblePassword(false);
                  }}
                >
                  Cancelar
                </Button>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="box_e-cnpj">
              <p>
                Seu e-CNPJ foi enviado com sucesso!
                <br />
                Deseja informar filiais que utilizam este mesmo certificado?
              </p>
              <div className="d-flex mt-4">
                <Button
                  color="primary"
                  variant="contained"
                  className="mr-2"
                  onClick={() => setStep(3)}
                >
                  Sim
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleCancel}
                >
                  Não
                </Button>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className="box_e-cnpj">
              <p>Informe as filiais que deseja cadastrar</p>
              <div className="d-flex flex-column justify-content-center align-items-start">
                {branchs.map((cnpj, index) => (
                  <div key={index} className="d-flex align-items-center mt-2">
                    {values.cnpj.slice(0, 11)}
                    <input
                      type="text"
                      placeholder="0000-00"
                      value={cnpj}
                      style={{
                        maxWidth: '72px',
                        marginRight: index === 0 ? '44px' : '0px',
                      }}
                      onChange={(e) =>
                        handleChangeByIndex(
                          e.target.value.replace(/[^\d]/g, ''),
                          index
                        )
                      }
                    />
                    {index <= branchs.length - 1 && index > 0 && (
                      <button
                        type="button"
                        style={{
                          background: 'none',
                          border: 'none',
                          padding: '10px',
                        }}
                        onClick={() =>
                          setBranchs(branchs.filter((b, i) => i !== index))
                        }
                      >
                        <HighlightOff />
                      </button>
                    )}
                  </div>
                ))}
                <button
                  type="button"
                  style={{
                    background: 'none',
                    border: 'none',
                    padding: '10px',
                  }}
                  onClick={() => setBranchs([...branchs, ''])}
                >
                  <AddCircle />
                </button>
              </div>
              <div className="mt-4">
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  disabled={
                    !!(
                      branchs.length === 0 ||
                      branchs[0] === '' ||
                      branchs.find((b) => b.length < 7 || b === '')
                    )
                  }
                  onClick={handleAdd}
                >
                  Adicionar
                </button>
                <button
                  type="button"
                  className="btn btn-light btn-sm ml-2"
                  onClick={() => {
                    handleCancel();
                  }}
                >
                  Cancelar
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default AddEcnpj;
