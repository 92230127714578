import React, { useState } from 'react';

import { TextField, Button, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Link } from 'react-router-dom';

import axios from 'axios';

import { Container } from './styles';
import { validarCNPJ } from '../utils';
import { normalizeCnpj } from '../../main/Register/components/utils/normalize';

function Home() {
  const [email, setEmail] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({
    email: '',
    cnpj: '',
  });

  const [msg, setMsg] = useState({ type: '', value: '' });

  async function handleSubmit(e) {
    e.preventDefault();
    setMsg({ type: '', value: '' });
    const lock_error = {
      email: '',
      cnpj: '',
    };

    if (email.indexOf('@') === -1) {
      lock_error.email = 'Email inválido';
    } else if (email.split('@')[1].indexOf('.') === -1) {
      lock_error.email = 'Email inválido';
    }
    if (!validarCNPJ(cnpj)) {
      lock_error.cnpj = 'CNPJ não é válido';
    }
    if (lock_error.email !== '' || lock_error.cnpj !== '') {
      setErrors(lock_error);
      return;
    }
    setLoading(true);
    try {
      await axios.post('/customers/auth', {
        email,
        cnpj: cnpj.replace(/[^\d]+/g, ''),
      });
      setMsg({
        type: 'success',
        value: 'Foi enviado para o seu e-mail um link para autenticação.',
      });
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setMsg({
          type: 'error',
          value: 'Nenhum cadastro encontrado',
        });
      } else if (err.response && err.response.status === 400) {
        setMsg({
          type: 'error',
          value: err.response.data.message,
        });
      } else if (err.response && err.response.status === 500) {
        setMsg({
          type: 'error',
          value: 'Ocorreu um erro em nossos servidores, tente novamente',
        });
      } else {
        setMsg({
          type: 'error',
          value: 'Ocorreu um erro, tente novamente',
        });
      }
    }
    setLoading(false);
  }
  return (
    <Container>
      <div id="auth_container">
        <div className="d-flex justify-content-between align-items-center mb-5">
          <img src="/assets/images/logo_bfc.png" alt="BFC Capital Partners" />
          <h2>Entrar</h2>
        </div>
        <form onSubmit={handleSubmit} className="d-flex flex-column">
          {msg.value !== '' && <Alert severity={msg.type}>{msg.value}</Alert>}
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={(e) => {
              setErrors({
                email: '',
                cnpj: '',
              });
              setEmail(e.target.value);
            }}
            className="mb-2"
            error={errors.email !== ''}
            helperText={errors.email}
            required
          />
          <TextField
            label="CNPJ"
            type="text"
            value={cnpj}
            onChange={(e) => {
              setErrors({
                email: '',
                cnpj: '',
              });
              setCnpj(normalizeCnpj(e.target.value));
            }}
            className="mb-2"
            error={errors.cnpj !== ''}
            helperText={errors.cnpj}
            required
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className="mt-4"
            disabled={loading}
          >
            {loading ? (
              <CircularProgress style={{ color: '#fff', fontSize: '16px' }} />
            ) : (
              'ENVIAR E-MAIL PARA AUTENTICAÇÃO'
            )}
          </Button>
          <Link to="/sign-up">Criar conta</Link>
        </form>
      </div>
    </Container>
  );
}

export default Home;
