function isPast(date) {
  const [day, month, year] = date.split('/');
  const today = new Date();
  if (Number(year) > today.getFullYear()) {
    return false;
  }
  if (
    Number(month) > today.getMonth() + 1 &&
    Number(year) === today.getFullYear()
  ) {
    return false;
  }
  if (
    Number(day) > today.getDate() &&
    Number(month) === today.getMonth() + 1 &&
    Number(year) === today.getFullYear()
  ) {
    return false;
  }
  return true;
}

export default function validate(values) {
  const errors = {
    razao_social: '',
    nome_fantasia: '',
    insc_estadual: '',
    cep: '',
    endereco: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    uf: '',
    email_empresa: '',
    telefone_da_empresa: '',
    data_fundacao: '',
    site: '',
    n_funcionarios: '',
  };
  const keys = Object.keys(errors);
  function switchValidate(key, value) {
    switch (key) {
      case 'data_fundacao': {
        if (!value) {
          return 'Preencha esse campo';
        }
        if (value.length < 10) {
          return 'Preencha esse campo';
        }
        if (!isPast(value)) {
          return 'PREENCHA UMA DATA VÁLIDA';
        }
        return '';
      }
      case 'email_empresa': {
        if (!value) {
          return 'Preencha esse campo';
        }
        if (value.indexOf('@') === -1) {
          return 'E-MAIL NÃO É VÁLIDO';
        }
        if (value.split('@')[1].indexOf('.') === -1) {
          return 'E-MAIL NÃO É VÁLIDO';
        }
        return '';
      }
      case 'telefone_da_empresa': {
        if (!value) {
          return 'Preencha esse campo';
        }
        if (value.length < 14) {
          return 'Preencha esse campo';
        }
        return '';
      }
      case 'insc_estadual': {
        return '';
      }
      case 'complemento': {
        return '';
      }
      case 'site': {
        return '';
      }
      default: {
        if (!value) {
          return 'Preencha esse campo';
        }
        if (value.length === 0) {
          return 'Preencha esse campo';
        }
        return '';
      }
    }
  }
  for (let i = 0; i < keys.length; i += 1) {
    errors[keys[i]] = switchValidate(keys[i], values[keys[i]]);
  }

  console.log(errors);

  return errors;
}
