import React, { useState, useEffect } from 'react';

import { TextField, Button, CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { validarCNPJ } from '../utils';
import {
  normalizeCnpj,
  normalizePhone,
} from '../../main/Register/components/utils/normalize';

import { signInSuccess } from '../../../store/modules/auth/actions';

import { Container } from './styles';

function Register() {
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    cnpj: '',
    customer_name: '',
    email: '',
    phone: '',
  });
  const [errors, setErrors] = useState({
    cnpj: '',
    customer_name: '',
    email: '',
    phone: '',
  });

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get('cnpj')) {
      setValues({ ...values, cnpj: urlParams.get('cnpj') });
    }
  }, []); // eslint-disable-line

  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    setErrors({
      cnpj: '',
      customer_name: '',
      email: '',
      phone: '',
    });
    const lock_errors = {
      cnpj: '',
      customer_name: '',
      email: '',
      phone: '',
    };
    let count = 0;
    if (!validarCNPJ(values.cnpj)) {
      lock_errors.cnpj = 'CNPJ inválido';
      count += 1;
    }
    if (values.email.indexOf('@') === -1) {
      lock_errors.email = 'Preencha um email válido';
      count += 1;
    } else if (values.email.split('@')[1].indexOf('.') === -1) {
      lock_errors.email = 'Preencha um email válido';
      count += 1;
    }
    if (values.customer_name.length === 0) {
      lock_errors.customer_name = 'Preencha esse campo';
      count += 1;
    }
    if (values.phone.length < 14) {
      lock_errors.phone = 'Preencha esse campo';
      count += 1;
    }
    if (count > 0) {
      return setErrors(lock_errors);
    }
    setLoading(true);
    try {
      const { data } = await axios.post('/customers', {
        customer_name: values.customer_name,
        phone: values.phone,
        email: values.email,
        cnpj: values.cnpj.replace(/\D/g, ''),
        origin_register_id: Number(values.origin_register_id),
        user_placeholder: values.user_placeholder,
      });
      const { token } = data;
      const company = data;
      axios.defaults.headers.common.token = token;
      dispatch(
        signInSuccess(token, {
          ...company,
          customer_name: values.customer_name,
        })
      );
      toast.success('Cadastro realizado com sucesso');
    } catch (err) {
      if (err.response && err.response.status === 400) {
        toast.error(err.response.data.message);
      } else {
        toast.error('Ocorreu um erro, tente novamente');
      }
    }
    return setLoading(false);
  }

  return (
    <Container>
      <div id="auth_container">
        <div className="d-flex justify-content-between align-items-center mb-5">
          <img src="/assets/images/logo_bfc.png" alt="BFC Capital Partners" />
          <h2>Cadastro</h2>
        </div>
        <form className="d-flex flex-column" onSubmit={handleSubmit}>
          <TextField
            label="CNPJ"
            type="text"
            value={values.cnpj}
            className="mb-2"
            onChange={(e) => {
              setErrors({
                cnpj: '',
                customer_name: '',
                email: '',
                phone: '',
              });
              setValues({ ...values, cnpj: normalizeCnpj(e.target.value) });
            }}
            error={errors.cnpj !== ''}
            disabled={loading}
            helperText={errors.cnpj}
            required
          />
          <TextField
            label="Seu Nome"
            type="text"
            value={values.customer_name}
            className="mb-2"
            onChange={(e) => {
              setErrors({
                cnpj: '',
                customer_name: '',
                email: '',
                phone: '',
              });
              setValues({ ...values, customer_name: e.target.value });
            }}
            error={errors.customer_name !== ''}
            disabled={loading}
            helperText={errors.customer_name}
            required
          />
          <TextField
            label="Email"
            type="email"
            value={values.email}
            className="mb-2"
            onChange={(e) => {
              setErrors({
                cnpj: '',
                customer_name: '',
                email: '',
                phone: '',
              });
              setValues({ ...values, email: e.target.value });
            }}
            error={errors.email !== ''}
            disabled={loading}
            helperText={errors.email}
            required
          />
          <TextField
            label="Telefone"
            type="text"
            value={values.phone}
            className="mb-2"
            onChange={(e) => {
              setErrors({
                cnpj: '',
                customer_name: '',
                email: '',
                phone: '',
              });
              setValues({ ...values, phone: normalizePhone(e.target.value) });
            }}
            error={errors.phone !== ''}
            disabled={loading}
            helperText={errors.phone}
            required
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className="mt-4"
            disabled={loading}
          >
            {loading ? (
              <CircularProgress style={{ color: '#fff', fontSize: '16px' }} />
            ) : (
              'Cadastrar'
            )}
          </Button>
          {!loading && <Link to="/sign-in">Já possuo conta</Link>}
        </form>
      </div>
    </Container>
  );
}

export default Register;
