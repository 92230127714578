import React, { useState, useEffect } from 'react';

import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Close } from '@material-ui/icons';

import { useSelector, useDispatch } from 'react-redux';

import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import axios from 'axios';

import { updatePendencieRequest } from '../../../../../store/modules/company/actions';
import { signOut } from '../../../../../store/modules/auth/actions';

function Documents({ loadingShow, loadingClose }) {
  const dispatch = useDispatch();
  const { pendencies, end, status } = useSelector((state) => state.company);
  const [documents, setDocuments] = useState([]);
  const [sended, setSended] = useState([]);
  const [selected, setSelected] = useState([]);
  const [files, setFiles] = useState([]);
  const [step, setStep] = useState(1);
  const [documentsPendencies, setDocumentsPendencies] = useState([]);

  const [pendencieIdentified, setPendencieIdentified] = useState(false);

  async function loadDocumentsTypes() {
    loadingShow();
    if (pendencieIdentified) {
      setPendencieIdentified(false);
    }
    try {
      const { data } = await axios.get('/document_types');
      setDocuments(data);
      const response = await axios.get('/customer-register/documents');
      setSended(response.data.documents);
      setDocumentsPendencies(pendencies.filter((i) => i.type === 'document'));
    } catch (err) {
      if (
        (err.response && err.response.status === 401) ||
        (err.response && err.response.status === 403)
      ) {
        dispatch(signOut());
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde.'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente.');
      }
    }
    loadingClose();
  }

  useEffect(() => {
    loadDocumentsTypes();
  }, [pendencies]); // eslint-disable-line

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, application/pdf',
    onDrop: (acceptedFiles) => {
      const _files = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFiles([...files, ..._files]);
    },
  });

  const handleDelete = (index) => {
    const _files = [...files];
    _files.splice(index, 1);
    setFiles(_files);
  };

  function handleChange(id) {
    if (selected.find((i) => i === id)) {
      setSelected(selected.filter((i) => i !== id));
    } else {
      setSelected([...selected, id]);
    }
  }

  async function handleSubmit() {
    const obj = new FormData();
    for (let i = 0; i < files.length; i += 1) {
      obj.append('files[]', files[i]);
    }

    const _documents_type = selected.map((i) => i).join(',');
    if (pendencieIdentified) {
      loadingShow();
      try {
        const lock_pendencies = pendencies.filter((p) => p.type === 'document');
        const body_data = [];
        lock_pendencies.forEach((i) => {
          body_data.push({
            id: i.id,
            data: selected,
          });
        });
        await axios.put('/customer-register/pendencies', {
          pendencies: body_data,
        });
        dispatch(updatePendencieRequest());
      } catch (err) {
        loadingClose();
        if (
          (err.response && err.response.status === 401) ||
          (err.response && err.response.status === 403)
        ) {
          return dispatch(signOut());
        }
        if (err.response && err.response.status === 500) {
          return toast.error(
            'Ocorreu um erro em nossos servidores, tente novamente mais tarde.'
          );
        }
        return toast.error('Ocorreu um erro, tente novamente.');
      }
    }
    loadingShow();
    try {
      await axios.post(
        `customer-register/documents?document_types=${_documents_type}`,
        obj,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      toast.success('Documentos enviados');
      setFiles([]);
      setStep(1);
      setSelected([]);
    } catch (err) {
      if (
        (err.response && err.response.status === 401) ||
        (err.response && err.response.status === 403)
      ) {
        dispatch(signOut());
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde.'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente.');
      }
    }
    loadingClose();
    return false;
  }

  function verifyDisabled(field) {
    if (end === 1) {
      if (status === 'A') {
        return true;
      }
      const pendencie = pendencies.find(
        (i) => i.type === 'document' && i.data.find((d) => d === field)
      );
      if (pendencie) {
        if (!pendencieIdentified) {
          setPendencieIdentified(true);
        }
        return false;
      }
      return true;
    }
    return false;
  }

  const requiredDocuments = [
    'Contrato Social e/ou alteração contratual, se houver', 
    'Cópia Identidade e CPF dos sócios', 
    'Comprovante de residência atualizado do sócio válido até 03 meses da data de emissão (agua/luz/fone)',
    'Faturamento atualizado dos últimos 12 meses, assinado pelo contador'
  ]

  return (
    <div>
      {step === 1 && (
        <div>
          <h4 className="mb-3">Selecione os Documentos que serão enviados</h4>
          {documentsPendencies &&
            documentsPendencies.length > 0 &&
            documentsPendencies.map((p) => (
              <Alert severity="error" key={p.id} className="mb-2">
                <h6>{p.title}</h6>
                <p>{p.description}</p>
              </Alert>
            ))}
          <FormGroup>
            {documents.length > 0 &&
              documents.map((d) => {
                const pendent = !verifyDisabled(d.id);
                const send = sended.find(
                  (i) => i.document_type.id === d.id && i.status === 'S'
                );
                const rejected = sended.find(
                  (i) => i.document_type.id === d.id && i.status === 'D'
                );
                const approved = sended.find(
                  (i) =>
                    i.document_type.id === d.id &&
                    (i.status === 'A' || i.status === 'I')
                );
                const checked = selected.find((i) => i === d.id);
                if (send || approved || !pendent) {
                  return (
                    <FormControlLabel
                      key={d.id}
                      control={
                        <Checkbox
                          checked={checked}
                          style={{
                            color: '#3757a1',
                            opacity: '0.4',
                          }}
                          disabled={send || approved || !pendent}
                          onChange={() => handleChange(d.id)}
                        />
                      }
                      label={
                        <div className="d-flex align-items-center">
                          <strike>{d.name}{requiredDocuments.includes(d.name) ? ' *' : ''}</strike>
                          {send && (
                            <small className="ml-2 text-primary">Enviado</small>
                          )}
                          {(rejected ||
                            (pendent && end === 1 && status !== 'A')) && (
                            <small className="ml-2 text-danger">Pendente</small>
                          )}
                          {approved && (
                            <small className="ml-2 text-danger">Aprovado</small>
                          )}
                        </div>
                      }
                    />
                  );
                }
                return (
                  <FormControlLabel
                    key={d.id}
                    control={
                      <Checkbox
                        checked={checked}
                        style={{
                          color: '#3757a1',
                        }}
                        disabled={send || approved || !pendent}
                        onChange={() => handleChange(d.id)}
                      />
                    }
                    label={
                      <div className="d-flex align-items-center">
                        <span>{d.name}{requiredDocuments.includes(d.name) ? ' *' : ''}</span>
                        {send && (
                          <small className="ml-2 text-primary">Enviado</small>
                        )}
                        {(rejected ||
                          (pendent && end === 1 && status !== 'A')) && (
                          <small className="ml-2 text-danger">Pendente</small>
                        )}
                        {approved && (
                          <small className="ml-2 text-danger">Aprovado</small>
                        )}
                      </div>
                    }
                  />
                );
              })}
          </FormGroup>
          <div>
            <span>* Documentos obrigatórios para realização do cadastro inicial.</span>
          </div>
          <Button
            type="button"
            color="primary"
            variant="contained"
            className="mt-3"
            disabled={
              selected.length === 0 || (end === 1 && !pendencieIdentified)
            }
            onClick={() => setStep(2)}
          >
            Continuar
          </Button>
        </div>
      )}
      {step === 2 && (
        <div>
          <h4 className="mb-3">Enviar documentos</h4>
          <div className="box_dropzone">
            <aside className="thumbsContainer">
              {files.map((file, index) => (
                <div className="thumb__container" key={index}>
                  <div className="thumbInner">
                    <span>{file.name}</span>
                    <button type="button" onClick={() => handleDelete(index)}>
                      <Close />
                    </button>
                  </div>
                </div>
              ))}
            </aside>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <p>
                Arraste e solte alguns arquivos aqui ou clique para selecionar
                os arquivos
              </p>
              <em>
                (Somente arquivos no formato: .jpg, .jpeg e .pdf serão aceitos)
              </em>
            </div>
          </div>
          <Button
            type="button"
            color="primary"
            variant="outlined"
            className="mt-4 mr-2"
            onClick={() => setStep(1)}
          >
            Voltar
          </Button>
          <Button
            type="button"
            color="primary"
            variant="contained"
            className="mt-4"
            onClick={handleSubmit}
            disabled={files.length === 0}
          >
            Enviar Arquivos
          </Button>
        </div>
      )}
    </div>
  );
}

export default Documents;
