import React, { useState, memo } from 'react';

import {
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
  FormHelperText,
} from '@material-ui/core';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import StateSelect from '../../utils/StateSelect';
import {
  normalizeDate,
  normalizeCpf,
  normalizeNumber,
  normalizeCep,
  normalizePhone,
} from '../../utils/normalize';
import { validationPF } from '../../utils/memberValidation';
import { signOut } from '../../../../../../store/modules/auth/actions';

function NewMemberPF({ resetForm, loadingShow, loadingClose, addMember }) {
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    fullname: '',
    date_of_birth: '',
    cpf: '',
    e_cpf: '',
    identity_doc: '',
    issuing_agency: '',
    issuing_agency_uf: '',
    nationality: '',
    profession: '',
    marital_status: '',
    wedding_registry: '',
    address: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    uf: '',
    zipcode: '',
    email: '',
    phone: '',
    in_stable_union: '',
    in_politically_exposed_person: '',
    mother_name: '',
    type: null,
  });
  const [errors, setErrors] = useState({
    fullname: '',
    date_of_birth: '',
    cpf: '',
    e_cpf: '',
    identity_doc: '',
    issuing_agency: '',
    issuing_agency_uf: '',
    nationality: '',
    profession: '',
    marital_status: '',
    wedding_registry: '',
    address: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    uf: '',
    zipcode: '',
    email: '',
    phone: '',
    in_stable_union: '',
    in_politically_exposed_person: '',
    mother_name: '',
    type: '',
  });
  const [valuesSpouses, setValuesSpouses] = useState({
    fullname: '',
    date_of_birth: '',
    cpf: '',
    e_cpf: '',
    identity: '',
    identity_oe: '',
    identity_uf: '',
    profession: '',
    email: '',
    phone: '',
  });
  const [errorsSpouses, setErrorsSpouses] = useState({
    fullname: '',
    date_of_birth: '',
    cpf: '',
    e_cpf: '',
    identity: '',
    identity_oe: '',
    identity_uf: '',
    profession: '',
    email: '',
    phone: '',
  });

  async function handleCep() {
    loadingShow();
    if (values.zipcode && values.zipcode.length === 9) {
      const api = axios.create({
        baseURL: 'https://viacep.com.br/ws',
      });
      delete api.defaults.headers.common.token;
      const response = await api.get(
        `/${values.zipcode.replace(/\D/g, '')}/json`
      );
      if (!response.data.erro) {
        const {
          uf,
          logradouro: address,
          bairro: neighborhood,
          localidade: city,
        } = response.data;
        setValues({
          ...values,
          uf,
          address,
          neighborhood,
          city,
        });
      }
    }
    loadingClose();
  }

  async function handleSubmit(e) {
    setErrors({
      fullname: '',
      date_of_birth: '',
      cpf: '',
      e_cpf: '',
      identity_doc: '',
      issuing_agency: '',
      issuing_agency_uf: '',
      nationality: '',
      profession: '',
      marital_status: '',
      wedding_registry: '',
      address: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      uf: '',
      zipcode: '',
      email: '',
      phone: '',
      in_stable_union: '',
      in_politically_exposed_person: '',
      mother_name: '',
      type: '',
    });
    setErrorsSpouses({
      fullname: '',
      date_of_birth: '',
      cpf: '',
      e_cpf: '',
      identity: '',
      identity_oe: '',
      identity_uf: '',
      profession: '',
      email: '',
      phone: '',
    });
    e.preventDefault();
    const { errors: errors_member, errors_spouses } = await validationPF(
      values,
      valuesSpouses
    );
    setErrors(errors_member);
    setErrorsSpouses(errors_spouses);
    const keys = Object.keys(errors_member);
    for (let i = 0; i < keys.length; i += 1) {
      if (errors_member[keys[i]] !== '') {
        return;
      }
    }
    const keys_spouses = Object.keys(errors_spouses);
    for (let i = 0; i < keys_spouses.length; i += 1) {
      if (errors_spouses[keys_spouses[i]] !== '') {
        return;
      }
    }
    loadingShow();
    try {
      let obj;
      if (
        values.marital_status !== '' &&
        values.marital_status !== 'Solteiro(a)' &&
        values.marital_status !== 'Separado(a)/Divorciado(a)' &&
        values.marital_status !== 'Viúvo(a)' &&
        values.wedding_registry !== '' &&
        values.wedding_registry !== 'Separação Total de Bens'
      ) {
        obj = {
          ...values,
          memberSpouses: valuesSpouses,
        };
      } else {
        obj = {
          ...values,
        };
      }
      const response = await axios.post('/customer-register/members', obj);
      toast.success('Membro adicionado com sucesso');
      resetForm();
      addMember(response.data);
    } catch (err) {
      if (
        (err.response && err.response.status === 401) ||
        (err.response && err.response.status === 403)
      ) {
        dispatch(signOut());
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde.'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente.');
      }
    }
    loadingClose();
  }

  return (
    <div>
      <h4 className="mb-4">Novo Sócio/Representante/Avalista/Procurador</h4>
      <form onSubmit={handleSubmit}>
        <fieldset className="mb-4">
          <FormControl style={{ width: '100%' }}>
            <InputLabel id="type_label">Tipo de Sócio</InputLabel>
            <Select
              style={{ width: '100%' }}
              required
              value={values.type}
              onChange={(e) =>
                setValues({
                  ...values,
                  type: e.target.value,
                })
              }
              labelId="type_label"
              error={errors.type !== ''}
            >
              <MenuItem value={1}>Sócio</MenuItem>
              <MenuItem value={2}>Representante</MenuItem>
              <MenuItem value={3}>Avalista</MenuItem>
              <MenuItem value={4}>Procurador</MenuItem>
            </Select>
            {errors.type !== '' && (
              <FormHelperText error>{errors.type}</FormHelperText>
            )}
          </FormControl>
        </fieldset>
        <fieldset>
          <legend className="mb-3">Dados Básicos</legend>
          <div className="wrap_fields">
            <TextField
              required
              label="Nome Completo"
              value={values.fullname}
              onChange={(e) =>
                setValues({ ...values, fullname: e.target.value })
              }
              error={errors.fullname !== ''}
              helperText={errors.fullname}
            />
            <TextField
              required
              label="Data de Nascimento"
              value={values.date_of_birth}
              onChange={(e) =>
                setValues({
                  ...values,
                  date_of_birth: normalizeDate(e.target.value),
                })
              }
              error={errors.date_of_birth !== ''}
              helperText={errors.date_of_birth}
            />
            <TextField
              required
              label="CPF"
              value={values.cpf}
              onChange={(e) =>
                setValues({ ...values, cpf: normalizeCpf(e.target.value) })
              }
              error={errors.cpf !== ''}
              helperText={errors.cpf}
            />
            <FormControl style={{ width: '100%' }}>
              <InputLabel id="e_cpf_label">Possui e-CPF?</InputLabel>
              <Select
                style={{ width: '100%' }}
                required
                value={values.e_cpf}
                onChange={(e) =>
                  setValues({
                    ...values,
                    e_cpf: e.target.value,
                  })
                }
                labelId="e_cpf_label"
                error={errors.e_cpf !== ''}
              >
                <MenuItem value="sim">Sim</MenuItem>
                <MenuItem value="nao">Não</MenuItem>
              </Select>
              {errors.e_cpf !== '' && (
                <FormHelperText error>{errors.e_cpf}</FormHelperText>
              )}
            </FormControl>
            <TextField
              required
              label="Número do RG"
              value={values.identity_doc}
              onChange={(e) =>
                setValues({ ...values, identity_doc: e.target.value })
              }
              error={errors.identity_doc !== ''}
              helperText={errors.identity_doc}
            />
            <TextField
              required
              label="Órgão Emissor"
              value={values.issuing_agency}
              onChange={(e) =>
                setValues({ ...values, issuing_agency: e.target.value })
              }
              error={errors.issuing_agency !== ''}
              helperText={errors.issuing_agency}
            />
            <StateSelect
              required
              label="Estado do RG"
              value={values.issuing_agency_uf}
              onChange={(e) =>
                setValues({ ...values, issuing_agency_uf: e.target.value })
              }
              error={errors.issuing_agency_uf !== ''}
              helperText={errors.issuing_agency_uf}
            />
            <TextField
              required
              label="Nacionalidade"
              value={values.nationality}
              onChange={(e) =>
                setValues({ ...values, nationality: e.target.value })
              }
              error={errors.nationality !== ''}
              helperText={errors.nationality}
            />
            <TextField
              required
              label="Profissão"
              value={values.profession}
              onChange={(e) =>
                setValues({ ...values, profession: e.target.value })
              }
              error={errors.profession !== ''}
              helperText={errors.profession}
            />
            <TextField
              required
              label="Nome da Mãe"
              value={values.mother_name}
              onChange={(e) =>
                setValues({ ...values, mother_name: e.target.value })
              }
              error={errors.mother_name !== ''}
              helperText={errors.mother_name}
            />
            <FormControl style={{ width: '100%' }}>
              <InputLabel id="public_person_label">Pessoa pública?</InputLabel>
              <Select
                style={{ width: '100%' }}
                required
                value={values.in_politically_exposed_person}
                onChange={(e) =>
                  setValues({
                    ...values,
                    in_politically_exposed_person: e.target.value,
                  })
                }
                error={errors.in_politically_exposed_person !== ''}
                labelId="public_person_label"
              >
                <MenuItem value="sim">Sim</MenuItem>
                <MenuItem value="nao">Não</MenuItem>
              </Select>
              {errors.in_politically_exposed_person !== '' && (
                <FormHelperText error>
                  {errors.in_politically_exposed_person}
                </FormHelperText>
              )}
            </FormControl>
          </div>
        </fieldset>
        <fieldset>
          <legend className="mb-3">Endereço</legend>
          <div className="wrap_fields">
            <TextField
              required
              label="CEP"
              value={values.zipcode}
              onBlur={handleCep}
              onChange={(e) =>
                setValues({ ...values, zipcode: normalizeCep(e.target.value) })
              }
              error={errors.zipcode !== ''}
              helperText={errors.zipcode}
            />
            <TextField
              required
              label="Logradouro"
              value={values.address}
              onChange={(e) =>
                setValues({ ...values, address: e.target.value })
              }
              error={errors.address !== ''}
              helperText={errors.address}
            />
            <TextField
              required
              label="Número"
              value={values.number}
              onChange={(e) =>
                setValues({
                  ...values,
                  number: normalizeNumber(e.target.value),
                })
              }
              error={errors.number !== ''}
              helperText={errors.number}
            />
            <TextField
              label="Complemento"
              value={values.complement}
              onChange={(e) =>
                setValues({ ...values, complement: e.target.value })
              }
            />
            <TextField
              required
              label="Bairro"
              value={values.neighborhood}
              onChange={(e) =>
                setValues({ ...values, neighborhood: e.target.value })
              }
              error={errors.neighborhood !== ''}
              helperText={errors.neighborhood}
            />
            <TextField
              required
              label="Cidade"
              value={values.city}
              onChange={(e) => setValues({ ...values, city: e.target.value })}
              error={errors.city !== ''}
              helperText={errors.city}
            />
            <StateSelect
              required
              label="Estado"
              value={values.uf}
              onChange={(e) => setValues({ ...values, uf: e.target.value })}
              error={errors.uf !== ''}
              helperText={errors.uf}
            />
          </div>
        </fieldset>
        <fieldset>
          <legend className="mb-3">Dados de Contato</legend>
          <div className="wrap_fields">
            <TextField
              required
              type="email"
              label="Email"
              value={values.email}
              onChange={(e) => setValues({ ...values, email: e.target.value })}
              error={errors.email !== ''}
              helperText={errors.email}
            />
            <TextField
              required
              label="Telefone"
              value={values.phone}
              onChange={(e) =>
                setValues({ ...values, phone: normalizePhone(e.target.value) })
              }
              error={errors.phone !== ''}
              helperText={errors.phone}
            />
          </div>
        </fieldset>
        <fieldset>
          <legend className="mb-3">Dados de Relacionamento</legend>
          <div className="wrap_fields">
            <FormControl style={{ width: '100%' }}>
              <InputLabel id="civil_state_label">Estado civil</InputLabel>
              <Select
                style={{ width: '100%' }}
                labelId="civil_state_label"
                value={values.marital_status}
                onChange={(e) => {
                  if (
                    e.target.value === 'Casado(a)' &&
                    values.wedding_registry !== 'Separação Total de Bens'
                  ) {
                    setValues({
                      ...values,
                      in_stable_union: 'nao',
                      marital_status: e.target.value,
                    });
                  } else {
                    setValues({
                      ...values,
                      marital_status: e.target.value,
                    });
                  }
                }}
                required
                error={errors.marital_status !== ''}
              >
                <MenuItem value="Solteiro(a)">Solteiro(a)</MenuItem>
                <MenuItem value="Casado(a)">Casado(a)</MenuItem>
                <MenuItem value="Separado(a)/Divorciado(a)">
                  Separado(a)/Divorciado(a)
                </MenuItem>
                <MenuItem value="Viúvo(a)">Viúvo(a)</MenuItem>
              </Select>
              {errors.marital_status !== '' && (
                <FormHelperText error>{errors.marital_status}</FormHelperText>
              )}
            </FormControl>
            <FormControl style={{ width: '100%' }}>
              <InputLabel id="in_stable_union_label">
                Possui união estável?
              </InputLabel>
              <Select
                style={{ width: '100%' }}
                required
                value={
                  values.marital_status === 'Casado(a)' &&
                  values.wedding_registry !== 'Separação Total de Bens'
                    ? 'nao'
                    : values.in_stable_union
                }
                disabled={
                  values.marital_status === 'Casado(a)' &&
                  values.wedding_registry !== 'Separação Total de Bens'
                }
                onChange={(e) =>
                  setValues({
                    ...values,
                    in_stable_union: e.target.value,
                  })
                }
                error={errors.in_stable_union !== ''}
                labelId="in_stable_union_label"
              >
                <MenuItem value="sim">Sim</MenuItem>
                <MenuItem value="nao">Não</MenuItem>
              </Select>
              {errors.in_stable_union !== '' && (
                <FormHelperText error>{errors.in_stable_union}</FormHelperText>
              )}
            </FormControl>
            {values.marital_status === 'Casado(a)' && (
              <FormControl style={{ width: '100%' }}>
                <InputLabel id="regime_civil_state_label">
                  Regime do estado civil
                </InputLabel>
                <Select
                  style={{ width: '100%' }}
                  labelId="regime_civil_state_label"
                  value={values.wedding_registry}
                  onChange={(e) => {
                    if (e.target.value !== 'Separação Total de Bens') {
                      setValues({
                        ...values,
                        in_stable_union: 'nao',
                        wedding_registry: e.target.value,
                      });
                    } else {
                      setValues({
                        ...values,
                        wedding_registry: e.target.value,
                      });
                    }
                  }}
                  error={errors.wedding_registry !== ''}
                  required
                >
                  <MenuItem value="Comunhão de Bens">Comunhão de Bens</MenuItem>
                  <MenuItem value="Comunhão Parcial de Bens">
                    Comunhão Parcial de Bens
                  </MenuItem>
                  <MenuItem value="Separação Total de Bens">
                    Separação Total de Bens
                  </MenuItem>
                </Select>
                {errors.wedding_registry && (
                  <FormHelperText error>
                    {errors.wedding_registry}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          </div>
        </fieldset>
        {values.marital_status === 'Casado(a)' &&
          values.wedding_registry !== 'Separação Total de Bens' && (
            <fieldset>
              <legend className="mb-3">Dados do Cônjuge</legend>
              <div className="wrap_fields">
                <TextField
                  required
                  label="Nome Completo"
                  value={valuesSpouses.fullname}
                  onChange={(e) =>
                    setValuesSpouses({
                      ...valuesSpouses,
                      fullname: e.target.value,
                    })
                  }
                  error={errorsSpouses.fullname !== ''}
                  helperText={errorsSpouses.fullname}
                />
                <TextField
                  required
                  label="Data de Nascimento"
                  value={valuesSpouses.date_of_birth}
                  onChange={(e) =>
                    setValuesSpouses({
                      ...valuesSpouses,
                      date_of_birth: normalizeDate(e.target.value),
                    })
                  }
                  error={errorsSpouses.date_of_birth !== ''}
                  helperText={errorsSpouses.date_of_birth}
                />
                <TextField
                  required
                  label="CPF"
                  value={valuesSpouses.cpf}
                  onChange={(e) =>
                    setValuesSpouses({
                      ...valuesSpouses,
                      cpf: normalizeCpf(e.target.value),
                    })
                  }
                  error={errorsSpouses.cpf !== ''}
                  helperText={errorsSpouses.cpf}
                />
                <FormControl style={{ width: '100%' }}>
                  <InputLabel id="e_cpf_label-spouses">
                    Possui e-CPF?
                  </InputLabel>
                  <Select
                    style={{ width: '100%' }}
                    required
                    value={valuesSpouses.e_cpf}
                    onChange={(e) =>
                      setValuesSpouses({
                        ...valuesSpouses,
                        e_cpf: e.target.value,
                      })
                    }
                    labelId="e_cpf_label-spouses"
                    error={errorsSpouses.e_cpf !== ''}
                  >
                    <MenuItem value="sim">Sim</MenuItem>
                    <MenuItem value="nao">Não</MenuItem>
                  </Select>
                  {errorsSpouses.e_cpf && (
                    <FormHelperText error>{errorsSpouses.e_cpf}</FormHelperText>
                  )}
                </FormControl>
                <TextField
                  required
                  label="Número do RG"
                  value={valuesSpouses.identity}
                  onChange={(e) =>
                    setValuesSpouses({
                      ...valuesSpouses,
                      identity: e.target.value,
                    })
                  }
                  error={errorsSpouses.identity !== ''}
                  helperText={errorsSpouses.identity}
                />
                <TextField
                  required
                  label="Órgão Emissor"
                  value={valuesSpouses.identity_oe}
                  onChange={(e) =>
                    setValuesSpouses({
                      ...valuesSpouses,
                      identity_oe: e.target.value,
                    })
                  }
                  error={errorsSpouses.identity_oe !== ''}
                  helperText={errorsSpouses.identity_oe}
                />
                <StateSelect
                  required
                  label="Estado do RG"
                  value={valuesSpouses.identity_uf}
                  onChange={(e) =>
                    setValuesSpouses({
                      ...valuesSpouses,
                      identity_uf: e.target.value,
                    })
                  }
                  error={errorsSpouses.identity_uf !== ''}
                  helperText={errorsSpouses.identity_uf}
                />
                <TextField
                  required
                  label="Profissão"
                  value={valuesSpouses.profession}
                  onChange={(e) =>
                    setValuesSpouses({
                      ...valuesSpouses,
                      profession: e.target.value,
                    })
                  }
                  error={errorsSpouses.profession !== ''}
                  helperText={errorsSpouses.profession}
                />
                <TextField
                  required
                  type="email"
                  label="Email"
                  value={valuesSpouses.email}
                  onChange={(e) =>
                    setValuesSpouses({
                      ...valuesSpouses,
                      email: e.target.value,
                    })
                  }
                  error={errorsSpouses.email !== ''}
                  helperText={errorsSpouses.email}
                />
                <TextField
                  required
                  type="text"
                  label="Telefone"
                  value={valuesSpouses.phone}
                  onChange={(e) =>
                    setValuesSpouses({
                      ...valuesSpouses,
                      phone: normalizePhone(e.target.value),
                    })
                  }
                  error={errorsSpouses.phone !== ''}
                  helperText={errorsSpouses.phone}
                />
              </div>
            </fieldset>
          )}
        <div className="mt-3">
          <Button type="submit" color="primary" variant="contained">
            Salvar
          </Button>
          <Button
            type="button"
            color="primary"
            variant="outlined"
            className="ml-2"
            onClick={() => resetForm()}
          >
            Cancelar
          </Button>
        </div>
      </form>
    </div>
  );
}

export default memo(NewMemberPF);
