import React, { useState, useEffect } from 'react';

import { CircularProgress } from '@material-ui/core';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';

import { signInSuccess, signOut } from '../../../store/modules/auth/actions';

function Auth({ match }) {
  const [loading, setLoading] = useState(true);
  const [msg, setMsg] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const { token: authenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    const { token } = match.params;
    async function loadToken() {
      try {
        axios.defaults.headers.common.token = token;
        const { data } = await axios.get('/customer-register/customer');
        setMsg('Autenticado com sucesso, redirecionando para a plataforma...');
        dispatch(signInSuccess(token, data));
        history.push('/dashboard');
      } catch (err) {
        if (
          (err.response && err.response.status === 403) ||
          (err.response && err.response.status === 401)
        ) {
          setMsg('Token inválido');
        } else {
          setMsg('Ocorreu um erro, tente novamente mais tarde.');
        }
      }
      setLoading(false);
    }
    if (authenticated === token) {
      history.push('/dashboard');
    } else {
      delete axios.defaults.headers.common.token;
      dispatch(signOut());
      loadToken();
    }
  }, []); // eslint-disable-line

  return (
    <Container>
      <div id="auth_container">
        <img src="/assets/images/logo_bfc.png" alt="BFC Capital Partners" />
        {loading && (
          <>
            <h2>Autenticando</h2>
            <CircularProgress style={{ fontSize: '60px', color: '#3757a1' }} />
          </>
        )}
        {msg !== '' && <h4 className="text-center">{msg}</h4>}
      </div>
    </Container>
  );
}

export default Auth;
