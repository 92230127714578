import React, { useState, useEffect, memo } from 'react';

import {
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
  FormHelperText,
} from '@material-ui/core';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Alert } from '@material-ui/lab';
import { useSelector, useDispatch } from 'react-redux';
import StateSelect from '../../utils/StateSelect';
import {
  normalizeCep,
  normalizeCnpj,
  normalizeNumber,
  normalizePhone,
} from '../../utils/normalize';
import { validationPJ } from '../../utils/memberValidation';

import { updatePendencieRequest } from '../../../../../../store/modules/company/actions';
import { signOut } from '../../../../../../store/modules/auth/actions';

function EditMemberPJ({
  resetForm,
  loadingShow,
  loadingClose,
  updateMember,
  member,
}) {
  const dispatch = useDispatch();
  const { pendencies, end, status } = useSelector((state) => state.company);
  const [pendencieIdentified, setPendencieIdentified] = useState(false);
  const [fieldsPendencies, setFieldsPendencies] = useState([]);
  const [values, setValues] = useState({
    fullname: '',
    cpf: '',
    address: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    uf: '',
    zipcode: '',
    email: '',
    phone: '',
    type: null,
  });
  const [errors, setErrors] = useState({
    fullname: '',
    cpf: '',
    address: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    uf: '',
    zipcode: '',
    email: '',
    phone: '',
    type: '',
  });

  useEffect(() => {
    if (Object.keys(member).length > 0) {
      setValues({ ...member, memberSpouses: undefined });
    }
  }, [member]);

  async function handleCep() {
    loadingShow();
    if (values.zipcode && values.zipcode.length === 9) {
      const api = axios.create({
        baseURL: 'https://viacep.com.br/ws',
      });
      delete api.defaults.headers.common.token;
      const response = await api.get(
        `/${values.zipcode.replace(/\D/g, '')}/json`
      );
      if (!response.data.erro) {
        const {
          uf,
          logradouro: address,
          bairro: neighborhood,
          localidade: city,
        } = response.data;
        setValues({
          ...values,
          uf,
          address,
          neighborhood,
          city,
        });
      }
    }
    loadingClose();
  }

  async function handleResolvePendencie() {
    const lock_pendencies = pendencies.filter(
      (p) => p.type === 'member' && p.member_id === member.id
    );
    const body_data = [];
    lock_pendencies.forEach((i) => {
      const data_send = {};
      for (let c = 0; c < fieldsPendencies.length; c += 1) {
        data_send[fieldsPendencies[c]] = values[fieldsPendencies[c]];
      }
      body_data.push({
        id: i.id,
        data: data_send,
      });
    });
    loadingShow();
    try {
      await axios.put('/customer-register/pendencies', {
        pendencies: body_data,
      });
      dispatch(updatePendencieRequest());
      toast.success('Pendência resolvida');
      updateMember();
      resetForm();
    } catch (err) {
      if (
        (err.response && err.response.status === 401) ||
        (err.response && err.response.status === 403)
      ) {
        dispatch(signOut());
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde.'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente.');
      }
    }
    return loadingClose();
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setErrors({
      fullname: '',
      cpf: '',
      address: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      uf: '',
      zipcode: '',
      email: '',
      phone: '',
      type: '',
    });
    const lock_error = await validationPJ(values);
    setErrors(lock_error);
    const keys = Object.keys(lock_error);
    for (let i = 0; i < keys.length; i += 1) {
      if (lock_error[keys[i]] !== '') {
        return;
      }
    }
    if (pendencieIdentified) {
      handleResolvePendencie();
      return;
    }
    loadingShow();
    try {
      await axios.put(`/customer-register/members/${member.id}`, values);
      toast.success('Membro atualizado com sucesso');
      resetForm();
      updateMember();
    } catch (err) {
      if (
        (err.response && err.response.status === 401) ||
        (err.response && err.response.status === 403)
      ) {
        dispatch(signOut());
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde.'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente.');
      }
    }
    loadingClose();
  }

  function verifyDisabled(field) {
    if (end === 1) {
      if (status === 'A') {
        return true;
      }
      const pendencie = pendencies.find(
        (i) =>
          i.type === 'member' &&
          i.member_id === member.id &&
          i.data[field] !== undefined
      );
      if (pendencie) {
        if (!fieldsPendencies.find((i) => i === field)) {
          setFieldsPendencies([...fieldsPendencies, field]);
        }
        if (!pendencieIdentified) {
          setPendencieIdentified(true);
        }
        return false;
      }
      return true;
    }
    return false;
  }

  return (
    <div>
      <h4 className="mb-4">Novo Sócio/Avalista</h4>
      {pendencieIdentified &&
        pendencies.map((p) => {
          if (p.type === 'member' && p.member_id === member.id) {
            return (
              <Alert severity="error" key={p.id} className="mb-2">
                <h6>{p.title}</h6>
                <p>{p.description}</p>
              </Alert>
            );
          }
          return '';
        })}
      <form onSubmit={handleSubmit}>
        <fieldset className="mb-4">
          <FormControl
            style={{ width: '100%' }}
            disabled={verifyDisabled('type')}
          >
            <InputLabel id="type_label">Tipo de Sócio</InputLabel>
            <Select
              style={{ width: '100%' }}
              required
              value={values.type || ''}
              onChange={(e) =>
                setValues({
                  ...values,
                  type: e.target.value,
                })
              }
              labelId="type_label"
              error={
                errors.type !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('type'))
              }
            >
              <MenuItem value={1}>Sócio</MenuItem>
              <MenuItem value={3}>Avalista</MenuItem>
            </Select>
            {errors.type !== '' && (
              <FormHelperText error>{errors.type}</FormHelperText>
            )}
          </FormControl>
        </fieldset>
        <fieldset>
          <legend className="mb-3">Dados Básicos</legend>
          <div className="wrap_fields">
            <TextField
              required
              label="CNPJ"
              value={values.cpf}
              onChange={(e) =>
                setValues({ ...values, cpf: normalizeCnpj(e.target.value) })
              }
              disabled={verifyDisabled('cpf')}
              error={
                errors.cpf !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('cpf'))
              }
              helperText={errors.cpf}
            />
            <TextField
              required
              label="Razão Social"
              value={values.fullname}
              onChange={(e) =>
                setValues({ ...values, fullname: e.target.value })
              }
              disabled={verifyDisabled('fullname')}
              error={
                errors.fullname !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('fullname'))
              }
              helperText={errors.fullname}
            />
          </div>
        </fieldset>
        <fieldset>
          <legend className="mb-3">Endereço</legend>
          <div className="wrap_fields">
            <TextField
              required
              label="CEP"
              value={values.zipcode}
              onBlur={handleCep}
              onChange={(e) =>
                setValues({ ...values, zipcode: normalizeCep(e.target.value) })
              }
              disabled={verifyDisabled('zipcode')}
              error={
                errors.zipcode !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('zipcode'))
              }
              helperText={errors.zipcode}
            />
            <TextField
              required
              label="Logradouro"
              value={values.address}
              onChange={(e) =>
                setValues({ ...values, address: e.target.value })
              }
              disabled={verifyDisabled('address')}
              error={
                errors.address !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('address'))
              }
              helperText={errors.address}
            />
            <TextField
              required
              label="Número"
              value={values.number}
              onChange={(e) =>
                setValues({
                  ...values,
                  number: normalizeNumber(e.target.value),
                })
              }
              disabled={verifyDisabled('number')}
              error={
                errors.number !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('number'))
              }
              helperText={errors.number}
            />
            <TextField
              label="Complemento"
              value={values.complement}
              onChange={(e) =>
                setValues({ ...values, complement: e.target.value })
              }
              error={
                errors.complement !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('complement'))
              }
            />
            <TextField
              required
              label="Bairro"
              value={values.neighborhood}
              onChange={(e) =>
                setValues({ ...values, neighborhood: e.target.value })
              }
              disabled={verifyDisabled('neighborhood')}
              error={
                errors.neighborhood !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('neighborhood'))
              }
              helperText={errors.neighborhood}
            />
            <TextField
              required
              label="Cidade"
              value={values.city}
              onChange={(e) => setValues({ ...values, city: e.target.value })}
              disabled={verifyDisabled('city')}
              error={
                errors.city !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('city'))
              }
              helperText={errors.city}
            />
            <StateSelect
              required
              label="Estado"
              value={values.uf}
              onChange={(e) => setValues({ ...values, uf: e.target.value })}
              disabled={verifyDisabled('uf')}
              error={
                errors.uf !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('uf'))
              }
              helperText={errors.uf}
            />
          </div>
        </fieldset>
        <fieldset>
          <legend className="mb-3">Dados de Contato</legend>
          <div className="wrap_fields">
            <TextField
              required
              type="email"
              label="Email"
              value={values.email}
              onChange={(e) => setValues({ ...values, email: e.target.value })}
              disabled={verifyDisabled('email')}
              error={
                errors.email !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('email'))
              }
              helperText={errors.email}
            />
            <TextField
              required
              label="Telefone"
              value={values.phone}
              onChange={(e) =>
                setValues({ ...values, phone: normalizePhone(e.target.value) })
              }
              disabled={verifyDisabled('phone')}
              error={
                errors.phone !== '' ||
                (end === 1 && status !== 'A' && !verifyDisabled('phone'))
              }
              helperText={errors.phone}
            />
          </div>
        </fieldset>
        <div className="mt-3">
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={end === 1 ? !pendencieIdentified : false}
          >
            Salvar
          </Button>
          <Button
            type="button"
            color="primary"
            variant="outlined"
            className="ml-2"
            onClick={() => resetForm()}
          >
            Cancelar
          </Button>
        </div>
      </form>
    </div>
  );
}

export default memo(EditMemberPJ);
