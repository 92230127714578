import axios from 'axios';

function isPast(date) {
  const [day, month, year] = date.split('/');
  const today = new Date();
  if (Number(year) > today.getFullYear()) {
    return false;
  }
  if (
    Number(month) > today.getMonth() + 1 &&
    Number(year) === today.getFullYear()
  ) {
    return false;
  }
  if (
    Number(day) > today.getDate() &&
    Number(month) === today.getMonth() + 1 &&
    Number(year) === today.getFullYear()
  ) {
    return false;
  }
  return true;
}

export function validarCNPJ(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj === '') return false;

  if (cnpj.length !== 14) return false;

  if (
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  )
    return false;

  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) { // eslint-disable-line
    soma += numeros.charAt(tamanho - i) * pos--; // eslint-disable-line
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== Number(digitos.charAt(0))) return false;

  tamanho += 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) { // eslint-disable-line
    soma += numeros.charAt(tamanho - i) * pos--; // eslint-disable-line
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== Number(digitos.charAt(1))) return false;

  return true;
}

export function validarCPF(strCPF) {
  let soma;
  let resto;
  strCPF = strCPF.replace(/[^\d]+/g, '');
  soma = 0;
  if (strCPF === '00000000000') return false;

  for (let i = 1; i <= 9; i++) // eslint-disable-line
    soma += parseInt(strCPF.substring(i - 1, i)) * (11 - i); // eslint-disable-line
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(strCPF.substring(9, 10))) return false; // eslint-disable-line

  soma = 0;
  for (let i = 1; i <= 10; i++) // eslint-disable-line
    soma += parseInt(strCPF.substring(i - 1, i)) * (12 - i); // eslint-disable-line
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(strCPF.substring(10, 11))) return false; // eslint-disable-line
  return true;
}

function emailIsValid(email) {
  if (email.indexOf('@') === -1) {
    return false;
  }
  if (email.split('@')[1].indexOf('.') === -1) {
    return false;
  }
  return true;
}

export async function validationPF(values, valuesSpouses) {
  const errors = {
    fullname: '',
    date_of_birth: '',
    cpf: '',
    e_cpf: '',
    identity_doc: '',
    issuing_agency: '',
    issuing_agency_uf: '',
    nationality: '',
    profession: '',
    marital_status: '',
    wedding_registry: '',
    address: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    uf: '',
    zipcode: '',
    email: '',
    phone: '',
    in_stable_union: '',
    in_politically_exposed_person: '',
    mother_name: '',
    type: '',
  };
  const errors_spouses = {
    fullname: '',
    date_of_birth: '',
    cpf: '',
    e_cpf: '',
    identity: '',
    identity_oe: '',
    identity_uf: '',
    profession: '',
    email: '',
  };
  const keys = Object.keys(errors);
  const { data: members } = await axios.get('/customer-register/members');
  function switchValidate(key, value) {
    switch (key) {
      case 'cpf_spouses': {
        if (!value) {
          return 'Preencha esse campo';
        }
        if (!validarCPF(value)) {
          return 'CPF INVÁLIDO';
        }
        if (values && values.cpf && values.cpf === value) {
          return 'CPF NÃO PODE SER O MESMO DO CÔNJUGE';
        }
        return '';
      }
      case 'cpf': {
        if (!value) {
          return 'Preencha esse campo';
        }
        if (!validarCPF(value)) {
          return 'CPF INVÁLIDO';
        }
        if (valuesSpouses && valuesSpouses.cpf && valuesSpouses.cpf === value) {
          return 'CPF NÃO PODE SER O MESMO DO CÔNJUGE';
        }
        if (members.find((m) => m.id !== values.id && m.cpf === value)) {
          return 'CPF JÁ UTILIZADO';
        }
        return '';
      }
      case 'date_of_birth': {
        if (!value) {
          return 'Preencha esse campo';
        }
        if (value.length < 10) {
          return 'Preencha esse campo';
        }
        if (!isPast(value)) {
          return 'PREENCHA UMA DATA VÁLIDA';
        }
        return '';
      }
      case 'email': {
        if (!value) {
          return 'Preencha esse campo';
        }
        if (!emailIsValid(value)) {
          return 'E-MAIL NÃO É VÁLIDO';
        }
        return '';
      }
      case 'wedding_registry': {
        if (values.marital_status === 'Casado(a)') {
          if (!value) {
            return 'Preencha esse campo';
          }
          if (value === '') {
            return 'Preencha esse campo';
          }
        }
        return '';
      }
      case 'phone': {
        if (!value) {
          return 'Preencha esse campo';
        }
        if (value.length < 14) {
          return 'Preencha esse campo';
        }
        return '';
      }
      case 'complement': {
        return '';
      }
      default: {
        if (!value) {
          return 'Preencha esse campo';
        }
        if (value.length === 0) {
          return 'Preencha esse campo';
        }
        return '';
      }
    }
  }
  for (let i = 0; i < keys.length; i += 1) {
    errors[keys[i]] = switchValidate(keys[i], values[keys[i]]);
  }
  if (
    values.marital_status !== '' &&
    values.marital_status !== 'Solteiro(a)' &&
    values.marital_status !== 'Separado(a)/Divorciado(a)' &&
    values.marital_status !== 'Viúvo(a)' &&
    values.wedding_registry !== '' &&
    values.wedding_registry !== 'Separação Total de Bens'
  ) {
    const keys_spouses = Object.keys(errors_spouses);
    for (let i = 0; i < keys_spouses.length; i += 1) {
      if (keys_spouses[i] === 'cpf') {
        errors_spouses[keys_spouses[i]] = switchValidate(
          `${keys_spouses[i]}_spouses`,
          valuesSpouses[keys_spouses[i]]
        );
      } else {
        errors_spouses[keys_spouses[i]] = switchValidate(
          keys_spouses[i],
          valuesSpouses[keys_spouses[i]]
        );
      }
    }
  }

  return { errors, errors_spouses };
}

export async function validationPJ(values) {
  const errors = {
    fullname: '',
    cpf: '',
    address: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    uf: '',
    zipcode: '',
    email: '',
    phone: '',
    type: '',
  };
  const { data: members } = await axios.get('/customer-register/members');
  const { data: company } = await axios.get('/customer-register');
  const keys = Object.keys(errors);
  function switchValidate(key, value) {
    switch (key) {
      case 'cpf': {
        if (!value) {
          return 'Preencha esse campo';
        }
        if (!validarCNPJ(value)) {
          return 'CNPJ INVÁLIDO';
        }
        if (members.find((m) => m.id !== values.id && m.cpf === value)) {
          return 'CNPJ JÁ UTILIZADO';
        }
        if (company.cnpj === value) {
          return 'CNPJ NÃO PODE SER O MESMO DA EMPRESA';
        }
        return '';
      }
      case 'email': {
        if (!value) {
          return 'Preencha esse campo';
        }
        if (!emailIsValid(value)) {
          return 'E-MAIL NÃO É VÁLIDO';
        }
        return '';
      }
      case 'phone': {
        if (!value) {
          return 'Preencha esse campo';
        }
        if (value.length < 14) {
          return 'Preencha esse campo';
        }
        return '';
      }
      case 'complement': {
        return '';
      }
      default: {
        if (!value) {
          return 'Preencha esse campo';
        }
        if (value.length === 0) {
          return 'Preencha esse campo';
        }
        return '';
      }
    }
  }
  for (let i = 0; i < keys.length; i += 1) {
    errors[keys[i]] = switchValidate(keys[i], values[keys[i]]);
  }

  return errors;
}

export function validationPJOff(values) {
  const errors = {
    fullname: '',
    cpf: '',
    address: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    uf: '',
    zipcode: '',
    email: '',
    phone: '',
    type: '',
  };
  const keys = Object.keys(errors);
  function switchValidate(key, value) {
    switch (key) {
      case 'cpf': {
        if (!value) {
          return 'Preencha esse campo';
        }
        if (!validarCNPJ(value)) {
          return 'CNPJ INVÁLIDO';
        }
        return '';
      }
      case 'email': {
        if (!value) {
          return 'Preencha esse campo';
        }
        if (!emailIsValid(value)) {
          return 'E-MAIL NÃO É VÁLIDO';
        }
        return '';
      }
      case 'phone': {
        if (!value) {
          return 'Preencha esse campo';
        }
        if (value.length < 14) {
          return 'Preencha esse campo';
        }
        return '';
      }
      case 'complement': {
        return '';
      }
      default: {
        if (!value) {
          return 'Preencha esse campo';
        }
        if (value.length === 0) {
          return 'Preencha esse campo';
        }
        return '';
      }
    }
  }
  for (let i = 0; i < keys.length; i += 1) {
    errors[keys[i]] = switchValidate(keys[i], values[keys[i]]);
  }

  return errors;
}

export function validationPFOff(values, valuesSpouses) {
  const errors = {
    fullname: '',
    date_of_birth: '',
    cpf: '',
    e_cpf: '',
    identity_doc: '',
    issuing_agency: '',
    issuing_agency_uf: '',
    nationality: '',
    profession: '',
    marital_status: '',
    wedding_registry: '',
    address: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    uf: '',
    zipcode: '',
    email: '',
    phone: '',
    in_stable_union: '',
    in_politically_exposed_person: '',
    mother_name: '',
    type: '',
  };
  const errors_spouses = {
    fullname: '',
    date_of_birth: '',
    cpf: '',
    e_cpf: '',
    identity: '',
    identity_oe: '',
    identity_uf: '',
    profession: '',
    email: '',
  };
  const keys = Object.keys(errors);
  function switchValidate(key, value) {
    switch (key) {
      case 'cpf': {
        if (!value) {
          return 'Preencha esse campo';
        }
        if (!validarCPF(value)) {
          return 'CPF INVÁLIDO';
        }
        if (valuesSpouses && valuesSpouses.cpf && valuesSpouses.cpf === value) {
          return 'CPF NÃO PODE SER O MESMO DO CÔNJUGE';
        }
        return '';
      }
      case 'date_of_birth': {
        if (!value) {
          return 'Preencha esse campo';
        }
        if (value.length < 10) {
          return 'Preencha esse campo';
        }
        if (!isPast(value)) {
          return 'PREENCHA UMA DATA VÁLIDA';
        }
        return '';
      }
      case 'email': {
        if (!value) {
          return 'Preencha esse campo';
        }
        if (!emailIsValid(value)) {
          return 'E-MAIL NÃO É VÁLIDO';
        }
        return '';
      }
      case 'wedding_registry': {
        if (values.marital_status === 'Casado(a)') {
          if (!value) {
            return 'Preencha esse campo';
          }
          if (value === '') {
            return 'Preencha esse campo';
          }
        }
        return '';
      }
      case 'phone': {
        if (!value) {
          return 'Preencha esse campo';
        }
        if (value.length < 14) {
          return 'Preencha esse campo';
        }
        return '';
      }
      case 'complement': {
        return '';
      }
      default: {
        if (!value) {
          return 'Preencha esse campo';
        }
        if (value.length === 0) {
          return 'Preencha esse campo';
        }
        return '';
      }
    }
  }
  for (let i = 0; i < keys.length; i += 1) {
    errors[keys[i]] = switchValidate(keys[i], values[keys[i]]);
  }
  if (
    values.marital_status !== '' &&
    values.marital_status !== 'Solteiro(a)' &&
    values.marital_status !== 'Separado(a)/Divorciado(a)' &&
    values.marital_status !== 'Viúvo(a)' &&
    values.wedding_registry !== '' &&
    values.wedding_registry !== 'Separação Total de Bens' &&
    valuesSpouses !== null
  ) {
    const keys_spouses = Object.keys(errors_spouses);
    for (let i = 0; i < keys_spouses.length; i += 1) {
      errors_spouses[keys_spouses[i]] = switchValidate(
        keys_spouses[i],
        valuesSpouses[keys_spouses[i]]
      );
    }
  }

  return { errors, errors_spouses };
}
