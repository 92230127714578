import React from 'react';

// import { Container } from './styles';

function Footer() {
  return (
    <div className="container-fluid" style={{ padding: '30px 0px' }}>
      <div className="container">Todos os direitos reservados &copy; 2020</div>
    </div>
  );
}

export default Footer;
