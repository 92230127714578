import React, { useState } from 'react';

import {
  ExitToApp,
  NotificationImportant,
  Notifications,
} from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Notification } from './styles';

import { signOut } from '../../../../store/modules/auth/actions';

function Header() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { company, pendencies, end, status } = useSelector(
    (state) => state.company
  );
  return (
    <Container className="container-fluid">
      <div className="container">
        <div className="d-flex align-items-center">
          <img
            src="/assets/images/logo_white.png"
            alt="BFC Capital Partners"
            className="mr-3"
          />
          <span>
            Olá, {company.customer_name && company.customer_name.split(' ')[0]}
          </span>
        </div>
        <div className="align-items-center d-flex">
          {end === 0 && (
            <span className="status_register">Complete seu cadastro</span>
          )}
          {end === 1 &&
            status !== 'A' &&
            (pendencies.length > 0 ? (
              <span className="status_register red_bar">
                {pendencies.length} Pendência(s) em aberto
              </span>
            ) : (
              <span className="status_register blue_bar">
                Aguardando análise
              </span>
            ))}
          {status === 'A' && (
            <span className="status_register green_bar">Cadastro Aprovado</span>
          )}
          <button
            type="button"
            className={`btn btn-notification ${
              pendencies.length === 0 ? '' : 'pulled'
            }`}
            onClick={() => setOpen(!open)}
          >
            {pendencies.length === 0 ? (
              <Notifications />
            ) : (
              <NotificationImportant />
            )}
          </button>
          <button
            type="button"
            className="btn"
            onClick={() => {
              dispatch(signOut());
            }}
          >
            <ExitToApp /> Sair
          </button>
        </div>
        <Notification className={`${open ? 'notification_open' : ''}`}>
          {pendencies.length === 0 && <h5>Nenhuma pendência em aberto...</h5>}
          <div id="notifications_list">
            {pendencies.map((p, index) => (
              <div className="notification_item" key={index}>
                <strong>{p.title}</strong>
                <p>{p.description}</p>
              </div>
            ))}
          </div>
        </Notification>
      </div>
    </Container>
  );
}

export default Header;
